import { SendRounded as SendIcon } from '@mui/icons-material'
import { CircularProgress, Button, Grid, Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  manageChannelsButton: {
    justifyContent: 'flex-start',
  },
  nextStepButton: {},
  backStepButton: {
    marginRight: theme.spacing(),
  },
  brandSelectContainer: {
    marginTop: theme.spacing(2),
  },
  skipToBuilderButton: {
    textAlign: 'center',
  },
  bottomToolbar: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1.5),
    textAlign: 'right',
    '@media (max-height: 975px)': {
      width: '100%',
      position: 'fixed',
      left: 0,
      bottom: 0,
      height: 71,
      alignItems: 'center',
      marginBottom: 0,
      borderTop: `1px solid ${theme.palette.divider}`,
      paddingRight: theme.spacing(3),
      zIndex: 2,
      backgroundColor: theme.palette.common.white,
    },
  },
  spinner: {
    color: theme.palette.common.white,
  },
}))

interface Props {
  backAnchor?: string
  nextAnchor?: string
  backDisabled?: boolean
  nextDisabled?: boolean
  handlePrevious?: () => void
  handleNext?: () => void
  isSubmit?: boolean
  isSubmitting?: boolean
  organisationHidden: boolean
}

const Buttons = ({
  backAnchor = 'Back',
  nextAnchor = 'Next',
  handlePrevious,
  handleNext,
  backDisabled,
  nextDisabled,
  isSubmit,
  isSubmitting,
  organisationHidden = false,
}: Props) => {
  const classes = useStyles()

  return (
    <Grid container className={classes.bottomToolbar}>
      <Grid item xs={12}>
        <div style={{ position: 'relative' }}>
          <div>
            {!organisationHidden && handlePrevious ? (
              <Button
                color="primary"
                variant="text"
                className={classes.backStepButton}
                disableElevation
                disabled={backDisabled}
                onClick={handlePrevious}
              >
                {backAnchor}
              </Button>
            ) : null}

            <Button
              variant="contained"
              disableElevation
              className={classes.nextStepButton}
              color="primary"
              onClick={handleNext}
              disabled={nextDisabled || isSubmitting}
              type={isSubmit ? 'submit' : 'button'}
            >
              {isSubmitting ? (
                <CircularProgress size={16} className={classes.spinner} />
              ) : (
                nextAnchor
              )}
            </Button>
          </div>
        </div>
      </Grid>
    </Grid>
  )
}

export default Buttons
