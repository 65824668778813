import { useQuery, UseQueryOptions, useInfiniteQuery } from 'react-query'
import { api } from 'services'
import {
  ApiResponseError,
} from '@seeka-labs-internal/lib-api-app'
import { ApiResponseDtoOfListOfUserGrantedOrganisation } from '@seeka-labs-internal/lib-api-account'
import queryKeys, { OrganisationsFilterProps } from './queryKeys'

export const useOrganisations = (
  options?: UseQueryOptions<
    ApiResponseDtoOfListOfUserGrantedOrganisation,
    ApiResponseError,
    ApiResponseDtoOfListOfUserGrantedOrganisation,
    typeof queryKeys.organisations
  >
) => {
  return useQuery(queryKeys.organisations, () => api.account.listTenants(), {
    ...options,
    refetchOnMount: false,
    staleTime: Infinity,
  })
}

export const useInfiniteOrganisations = (
  filters: OrganisationsFilterProps,
  enabled?: boolean
) => {
  return useInfiniteQuery<
    ApiResponseDtoOfListOfUserGrantedOrganisation,
    ApiResponseError,
    ApiResponseDtoOfListOfUserGrantedOrganisation,
    ReturnType<typeof queryKeys.organisationsPage>
  >(
    queryKeys.organisationsPage(filters),
    () =>
      api.account.listTenants(filters.searchString),
    {
      getNextPageParam: (_lastPage, _allPages) => {
        return undefined;
        // const flattenedPagesLength = allPages.map((x) => x.result).flat().length
        // const currentPage = filters.pageSize / flattenedPagesLength - 1
        // const hasNextPage =
        //   currentPage < lastPage.result.totalCount / filters.pageSize - 1
        // return hasNextPage ? currentPage + 1 : undefined
      },
      enabled,
      staleTime: 500000,
    }
  )
}
