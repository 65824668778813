import classNames from 'classnames';

import { Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Alert, AlertTitle } from '@mui/material';
import { AlertProps } from '@mui/lab';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    lineHeight: '21px',
    fontSize: theme.typography.body2.fontSize,
  },
  withMargin: {
    marginBottom: theme.spacing(2),
  },
  inputMargin: {
    marginBottom: theme.spacing(2.5),
  },
  alertTitle: {
    marginTop: 0,
    marginBottom: 0,
  },
  gutters: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}))

interface Props extends AlertProps {
  message: string | JSX.Element
  title?: string
  inputMargin?: boolean
  withMargin?: boolean
  severity?: 'error' | 'success' | 'warning' | 'info'
  className?: string
  withGutters?: boolean
}

const CustomAlert = ({
  message,
  title,
  withMargin = false,
  inputMargin = false,
  severity = 'info',
  className,
  action,
  withGutters,
}: Props) => {
  const classes = useStyles()

  const root = classNames(
    {
      [classes.root]: true,
      [classes.withMargin]: withMargin,
      [classes.inputMargin]: inputMargin,
      [classes.gutters]: withGutters,
    },
    className
  )

  return (
    <Alert className={root} severity={severity} action={action}>
      {title && (
        <AlertTitle className={classes.alertTitle} color="inherit">
          <Typography color="inherit">{title}</Typography>
        </AlertTitle>
      )}
      {message}
    </Alert>
  )
}

export default CustomAlert
