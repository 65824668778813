import React from 'react'
import { Grid, Typography, Theme, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { HelpRounded as HelpIcon } from '@mui/icons-material'

const useStyles = makeStyles((theme: Theme) => ({
  headlineContainer: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(2),
  },
  icon: {
    color: theme.palette.primary.main,
    verticalAlign: 'text-top',
    marginLeft: theme.spacing(0.5),
    width: 20,
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.7,
    },
  },
  headline: {
    display: 'flex',
  },
}))

interface Props {
  mainStat: string
  icon?: JSX.Element
  amount?: number
  total?: number
  percentage?: number
  subtitle?: string | JSX.Element
  tooltip?: string
}

const DrawerStatsHeader = ({
  mainStat,
  icon,
  amount,
  total,
  percentage,
  subtitle,
  tooltip = '',
}: Props) => {
  const classes = useStyles()

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="baseline"
      className={classes.headlineContainer}
    >
      <Typography variant="h1" className={classes.headline}>
        {icon}
        <strong style={{ marginLeft: icon ? 16 : 0 }}>{mainStat}</strong>
      </Typography>
      <Typography variant="h4">
        <strong>
          {subtitle}
          {amount !== undefined && total !== undefined && `${amount}/${total}`}
          {percentage && ` (${percentage}%)`}
        </strong>
        {tooltip && (
          <Tooltip title={tooltip} placement="top" arrow>
            <HelpIcon className={classes.icon} />
          </Tooltip>
        )}
      </Typography>
    </Grid>
  )
}

export default DrawerStatsHeader
