import { DropdownDivider, DropdownMenu, TicketRevListItem } from 'components'
import config from 'config'
import { useInfiniteOrganisations, useMe } from 'hooks'
import React, { memo, useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'
import { removeToken } from 'utils'
import useSeekaInternalStaffControls from 'utils/useSeekaInternalStaffControls'

import {
  AccountCircleRounded as AccountDetailsIcon,
  FlashOnRounded as EnvIcon,
  ContactSupport as HelpIcon,
  PowerSettingsNewRounded as LogOutIcon,
  SwapHorizRounded as SwapIcon,
} from '@mui/icons-material'
import {
  Avatar,
  Chip,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Slide,
  Theme,
  Tooltip
} from '@mui/material'
import { grey } from '@mui/material/colors'
import makeStyles from '@mui/styles/makeStyles'
import { useSeekaConverge } from '@seeka-labs/converge-react'

import SwitchOrganisation from '../SwitchOrganisation'

const useStyles = makeStyles((theme: Theme) => ({
  listItem: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: 0,
    cursor: 'pointer',
    borderRadius: '50%',
    border: `2px solid ${theme.palette.common.white}`,
    transition: theme.transitions.create('border-color', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    '&:hover': {
      backgroundColor: 'transparent',
      border: `2px solid ${theme.palette.border}`,
    },
  },
  inMenuListItem: {
    padding: theme.spacing(0.5),
  },
  roundAvatar: {
    width: 38,
    height: 38,
    backgroundColor: grey[400],
    color: theme.palette.common.white,
    fontWeight: 600,
    fontSize: 18,
    textTransform: 'uppercase',
  },
  listItemAvatar: {
    minWidth: 40,
    border: `2px solid ${theme.palette.common.white}`,
    borderRadius: '50%',
    transition: theme.transitions.create('border-radius', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  primary: {
    lineHeight: '22px',
  },
  secondary: {
    lineHeight: '16px',
  },
  chip: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
    marginRight: theme.spacing(),
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(0.5),
    '&:hover': {
      cursor: 'pointer',
    },
  },
  chipLabel: {
    fontSize: 13,
    fontWeight: 700,
    paddingLeft: theme.spacing(),
  },
}))

type Props = {
  handleReCaptchaVerify: () => Promise<string | undefined>
  hideUserSettings?: boolean
}

const UserAvatar = ({ handleReCaptchaVerify, hideUserSettings }: Props) => {
  const queryClient = useQueryClient()
  const history = useHistory()
  const classes = useStyles()
  // prime org selector state
  useInfiniteOrganisations({
    searchString: '',
    pageSize: 100,
  })

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [menuOpen, setMenuOpen] = useState<'main' | 'switch-organisation'>('main')
  const [withAnimation, setWithAnimation] = useState(false)
  const intercom = useIntercom()

  const { data: meData } = useMe()

  const userName =
    meData?.result?.profile?.name || meData?.result?.profile?.surname
      ? `${meData?.result?.profile?.name || ''} ${meData?.result?.profile?.surname || ''}`
      : ''
  const userEmail = meData?.result?.profile?.email
  const profileImage = meData?.result?.profile?.images?.profileThumbnailImageUrl
  const avatarChars = `${meData?.result?.profile?.name?.charAt(0) || ''}${meData?.result?.profile?.surname?.charAt(0) || ''
    }`

  const converge = useSeekaConverge()

  useEffect(() => {
    if (menuOpen === 'switch-organisation') setWithAnimation(true)
  }, [menuOpen])

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setMenuOpen('main')
    setWithAnimation(false)
  }

  const goToPage = (page: string) => {
    handleClose()
    setTimeout(() => history.push(`/${page}`))
  }

  const handleSignOut = () => {
    if (config.intercomAppId) {
      try {
        intercom.update({
          email: undefined,
          name: undefined,
          userHash: undefined,
          userId: undefined,
          phone: undefined,
          company: undefined,
        })
      } catch (err) {
        console.error(err)
      }
    }
    removeToken()
    queryClient.clear()

    converge?.track.custom('WebAppLogout')
    history.push(`/login`)
  }

  const openIntercom = () => {
    intercom.show()
    handleClose()
  }

  const handleCloseSwitchOrganisation = () => setMenuOpen('main')

  const showHostingEnv = Boolean(
    meData?.result?.config.setting.values[
    'hostingEnvironment.Type.Name.ShowInUserInterface'
    ] === 'True'
  )
  const hostingEnv = meData?.result?.config.setting.values['hostingEnvironment.Type.Name']

  const staffControls = useSeekaInternalStaffControls()

  const mainMenu = (
    <>
      <ListItemButton
        style={{
          outline: 'none',
          minWidth: 270,
          cursor: hideUserSettings ? 'inherit' : 'pointer',
        }}
        onClick={() => !hideUserSettings && goToPage('settings/account/account-details')}
        className={classes.inMenuListItem}
      >
        <ListItemAvatar className={classes.listItemAvatar}>
          <Avatar className={classes.roundAvatar}>{avatarChars}</Avatar>
        </ListItemAvatar>
        <ListItemText
          style={{ paddingLeft: 8, ...staffControls.blurredStyling }}
          primary={userName || 'No name'} slot="div"
          secondary={userEmail || 'No e-mail'}
          primaryTypographyProps={{
            noWrap: true,
          }}
          secondaryTypographyProps={{
            noWrap: true,
          }}
          classes={{
            primary: classes.primary,
            secondary: classes.secondary,
          }}
        />
      </ListItemButton>
      <DropdownDivider />
      {!hideUserSettings && (
        <TicketRevListItem
          primaryText="User settings"
          icon={<AccountDetailsIcon />}
          onClick={() => goToPage('settings/account/account-details')}
          size="small"
          listItemSize="smallListItem"
        />
      )}
      {/* <TicketRevListItem
        primaryText="Organisation settings"
        icon={<PreferencesIcon />}
        onClick={() => goToPage('settings/organisation/general-settings')}
        size="small"
        listItemSize="smallListItem"
      /> */}
      <TicketRevListItem
        primaryText="Switch organisation"
        secondaryText={meData?.result.organisation.description}
        blurSecondaryText={staffControls.controls.isDemoModeEnabled}
        icon={<SwapIcon />}
        size="small"
        listItemSize="smallListItem"
        withChevron
        onClick={() => setMenuOpen('switch-organisation')}
      />
      <DropdownDivider />
      <TicketRevListItem
        primaryText="Support"
        icon={<HelpIcon />}
        onClick={openIntercom}
        size="small"
        listItemSize="smallListItem"
      />
      <DropdownDivider />
      <TicketRevListItem
        primaryText="Log out"
        icon={<LogOutIcon />}
        onClick={handleSignOut}
        size="small"
        listItemSize="smallListItem"
      />
    </>
  )

  return (
    <>
      {showHostingEnv && (
        <Tooltip
          title={`This hosting environment is set to ${hostingEnv}`}
          placement="left"
          arrow
        >
          <Chip
            component="span"
            label={hostingEnv}
            size="small"
            className={classes.chip}
            classes={{ label: classes.chipLabel }}
            icon={<EnvIcon />}
            color="secondary"
          />
        </Tooltip>
      )}
      <List disablePadding>
        <ListItemButton
          className={classes.listItem}
          onClick={handleClick}
          disableGutters
        >
          <ListItemAvatar className={classes.listItemAvatar}>
            <Tooltip title={userName || userEmail || ''} placement="left" arrow>
              <Avatar
                className={classes.roundAvatar}
                src={profileImage}
                alt={`${userName} profile image`}
              >
                {avatarChars || 'N'}
              </Avatar>
            </Tooltip>
          </ListItemAvatar>
        </ListItemButton>
      </List>
      {anchorEl && (
        <DropdownMenu
          anchorEl={anchorEl}
          handleClose={handleClose}
          disableAutoFocusItem
          autoFocus={false}
          variant="menu"
        >
          {menuOpen === 'main' && (
            <>
              {withAnimation ? (
                <Slide in direction="right">
                  <div>{mainMenu}</div>
                </Slide>
              ) : (
                mainMenu
              )}
            </>
          )}
          {meData && menuOpen === 'switch-organisation' && (
            <SwitchOrganisation
              selectedOrganisation={meData?.result.organisation}
              handleClose={handleCloseSwitchOrganisation}
              goToPage={goToPage}
              handleCloseDropdown={handleClose}
              handleReCaptchaVerify={handleReCaptchaVerify}
            />
          )}
        </DropdownMenu>
      )}
    </>
  )
}

export default memo(UserAvatar)
