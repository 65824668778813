import { useContext, useEffect, useState } from 'react'
import AppContext from 'context'
import { useQuery, UseQueryOptions } from 'react-query'
import {
  ApiResponseDtoOfPagedResultDtoOfShopifyStore,
  ApiResponseError,
  ApiResponseDtoOfPagedResultDtoOfFacebookPixel,
  ApiResponseDtoOfPagedResultDtoOfSnapchatPixel,
  ApiResponseDtoOfPagedResultDtoOfPinterestPixel,
  ApiResponseDtoOfPagedResultDtoOfTikTokPixel,
  ApiResponseDtoOfPagedResultDtoOfGoogleAnalyticsStream,
  ConvergeInstanceSummaryWithApiDetails,
  ApiResponseDtoOfListResultDtoOfConvergePipelineIntegrationInstance,
} from '@seeka-labs-internal/lib-api-app'
import { api } from 'services'
import queryKeys from './queryKeys'

export const useShopifyStores = (
  options?: UseQueryOptions<
    ApiResponseDtoOfPagedResultDtoOfShopifyStore,
    ApiResponseError,
    ApiResponseDtoOfPagedResultDtoOfShopifyStore,
    ReturnType<typeof queryKeys.shopifyStores>
  >
) => {
  const { selectedBrand } = useContext(AppContext)
  return useQuery(
    queryKeys.shopifyStores(selectedBrand.id),
    () => api.integrations.shopify.getAll([selectedBrand.id]),
    {
      ...options,
    }
  )
}

export const useFacebookPixels = (
  options?: UseQueryOptions<
    ApiResponseDtoOfPagedResultDtoOfFacebookPixel,
    ApiResponseError,
    ApiResponseDtoOfPagedResultDtoOfFacebookPixel,
    ReturnType<typeof queryKeys.facebookPixels>
  >
) => {
  const { selectedBrand } = useContext(AppContext)
  return useQuery(
    queryKeys.facebookPixels(selectedBrand.id),
    () => api.integrations.facebook.getAll([selectedBrand.id]),
    {
      ...options,
    }
  )
}

export const useSnapchatPixels = (
  options?: UseQueryOptions<
    ApiResponseDtoOfPagedResultDtoOfSnapchatPixel,
    ApiResponseError,
    ApiResponseDtoOfPagedResultDtoOfSnapchatPixel,
    ReturnType<typeof queryKeys.snapchatPixels>
  >
) => {
  const { selectedBrand } = useContext(AppContext)
  return useQuery(
    queryKeys.snapchatPixels(selectedBrand.id),
    () => api.integrations.snapchat.getAll([selectedBrand.id]),
    {
      ...options,
    }
  )
}

export const usePinterestPixels = (
  options?: UseQueryOptions<
    ApiResponseDtoOfPagedResultDtoOfPinterestPixel,
    ApiResponseError,
    ApiResponseDtoOfPagedResultDtoOfPinterestPixel,
    ReturnType<typeof queryKeys.snapchatPixels>
  >
) => {
  const { selectedBrand } = useContext(AppContext)
  return useQuery(
    queryKeys.pinterestPixels(selectedBrand.id),
    () => api.integrations.pinterest.getAll([selectedBrand.id]),
    {
      ...options,
    }
  )
}

export const useTiktokPixels = (
  options?: UseQueryOptions<
    ApiResponseDtoOfPagedResultDtoOfTikTokPixel,
    ApiResponseError,
    ApiResponseDtoOfPagedResultDtoOfTikTokPixel,
    ReturnType<typeof queryKeys.snapchatPixels>
  >
) => {
  const { selectedBrand } = useContext(AppContext)
  return useQuery(
    queryKeys.tikTokPixels(selectedBrand.id),
    () => api.integrations.tiktok.getAll([selectedBrand.id]),
    {
      ...options,
    }
  )
}

export const useGoogleAnalyticsPixels = (
  options?: UseQueryOptions<
    ApiResponseDtoOfPagedResultDtoOfGoogleAnalyticsStream,
    ApiResponseError,
    ApiResponseDtoOfPagedResultDtoOfGoogleAnalyticsStream,
    ReturnType<typeof queryKeys.googleAnalyticsPixels>
  >
) => {
  const { selectedBrand } = useContext(AppContext)
  return useQuery(
    queryKeys.googleAnalyticsPixels(selectedBrand.id),
    () => api.integrations.googleAnalytics.getAll([selectedBrand.id]),
    {
      ...options,
    }
  )
}

export const useConnectedPipelineIntegrations = (
  options?: UseQueryOptions<
    ApiResponseDtoOfListResultDtoOfConvergePipelineIntegrationInstance,
    ApiResponseError,
    ApiResponseDtoOfListResultDtoOfConvergePipelineIntegrationInstance,
    ReturnType<typeof queryKeys.connectedPipelineIntegrations>
  >
) => {
  const { selectedBrand } = useContext(AppContext)
  const [convergeInstance, setConvergeInstance] = useState<
    ConvergeInstanceSummaryWithApiDetails | undefined
  >(undefined)

  const loadConvergeInstances = async () => {
    const instances = await api.converge.getAllConvergeInstances(selectedBrand.id)
    if (instances.result.result) {
      setConvergeInstance(instances.result.result[0])
    }
  }

  const query = useQuery(
    queryKeys.connectedPipelineIntegrations(selectedBrand.id),
    () =>
      convergeInstance
        ? api.integrations.pipeline.getAllConnected([convergeInstance.id])
        : new Promise<ApiResponseDtoOfListResultDtoOfConvergePipelineIntegrationInstance>(
          (resolve) => {
            const res = {
              result: { items: [] },
            } as unknown as ApiResponseDtoOfListResultDtoOfConvergePipelineIntegrationInstance;
            resolve(res)
          }
        ),
    {
      ...options,
    }
  );

  useEffect(() => {
    if (selectedBrand && selectedBrand.id) {
      loadConvergeInstances()
    }
  }, [selectedBrand])

  useEffect(() => {
    if (convergeInstance && convergeInstance.id) {
      query.refetch();
    }
  }, [convergeInstance])

  return query
}
