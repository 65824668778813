export default async (file: File): Promise<string | undefined> => {


      // const reader = new FileReader()

      // reader.onabort = () => console.log('file reading was aborted')
      // reader.onerror = () => console.log('file reading has failed')
      // reader.onload = () => {
      //   // Do whatever you want with the file contents
      //   const binaryStr = reader.result
      //   console.log(binaryStr)
      // }


  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = () => resolve(reader.result as any)
    reader.onerror = (error) => reject(error)
    reader.onabort = () => reject('file reading was aborted')

    
    reader.readAsDataURL(file)

    setTimeout(() => {
      if (reader.readyState !== FileReader.DONE) {
        reject('file reading timed out');
      }
    }, 10000);  // 10 seconds timeout
  })
}
