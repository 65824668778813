import React from 'react'
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Paper, PaperContent } from 'components'

const useStyles = makeStyles((theme: Theme) => ({
  container: ({ containerHeight }: { containerHeight: number }) => ({
    zIndex: 1,
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    [`@media screen and (max-height: ${containerHeight}px)`]: {
      alignItems: 'flex-start',
      paddingTop: 85,
    },
    [theme.breakpoints.down(700)]: {
      margin: theme.spacing(2),
    },
  }),
  paper: {
    maxWidth: 610,
    width: '100%',
  },
  paperContent: {
    padding: '9%',
    [theme.breakpoints.down(1200)]: {
      padding: theme.spacing(5),
    },
    [theme.breakpoints.down(700)]: {
      padding: theme.spacing(3),
    },
  },
}))

const ContentCard = ({
  children,
  withPadding = true,
  containerHeight,
}: {
  children: React.ReactNode
  withPadding?: boolean
  containerHeight: number
}) => {
  const classes = useStyles({ containerHeight })

  return (
    <div className={classes.container} style={{ padding: !withPadding ? 0 : '' }}>
      <Paper className={classes.paper}>
        <PaperContent className={classes.paperContent}>{children}</PaperContent>
      </Paper>
    </div>
  )
}

export default ContentCard
