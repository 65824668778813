import { UseQueryOptions, useQuery } from 'react-query'
import { api } from 'services'
import {
  ApiResponseError,
  ItemListResponseOfTimeZoneInfo,
  ApiResponseDtoOfTokenListing,
} from '@seeka-labs-internal/lib-api-app'
import queryKeys from './queryKeys'

const { textTokens, timezones } = queryKeys

export const useTextTokens = (
  walletPassInstallEmailTemplate?: boolean,
  options?: UseQueryOptions<
    ApiResponseDtoOfTokenListing,
    ApiResponseError,
    ApiResponseDtoOfTokenListing,
    ReturnType<typeof textTokens>
  >
) => {
  return useQuery(
    textTokens(walletPassInstallEmailTemplate),
    () => api.general.referenceData.getAllTextTokens(walletPassInstallEmailTemplate),
    {
      ...options,
    }
  )
}

export const useTimezones = (
  options?: UseQueryOptions<
    ItemListResponseOfTimeZoneInfo,
    ApiResponseError,
    ItemListResponseOfTimeZoneInfo,
    typeof timezones
  >
) => {
  return useQuery(timezones, () => api.general.referenceData.getAllTimeZones(), {
    ...options,
  })
}
