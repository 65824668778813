import React from 'react'
import classNames from 'classnames'
import { TableHead, TableRow, TableCell, TableSortLabel, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Order, HeadCell } from './Table'

const useStyles = makeStyles((theme: Theme) => ({
  paddingLeft: {
    paddingLeft: `${theme.spacing()}!important`,
  },
  tableHeadCell: {
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.text.primary,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  tableHeadCellWithoutPadding: {    
    paddingTop: '0!important',
    paddingBottom:'0!important',
    paddingLeft: '0!important',
    paddingRight: '10px',
  },
  tr: {
    height: 36,
    backgroundColor: 'transparent',
    borderBottom: 0,
  },
  noPaddingLeft: {
    paddingLeft: 0,
  },
  sortingActive: {
    color: `${theme.palette.primary.main} !important`,
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shortest,
    }),
    '&:hover': {
      opacity: 0.7,
    },
  },
}))

export interface TableHeadProps {
  onRequestSort?: (event: React.MouseEvent<unknown>, property: string) => void
  order?: Order
  orderBy?: string
  utilityIcon?: JSX.Element
  headCells: HeadCell[]
}

const EnhancedTableHead = ({
  order,
  orderBy,
  onRequestSort,
  headCells,
  utilityIcon,
}: TableHeadProps) => {
  const classes = useStyles()

  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) =>
    onRequestSort && onRequestSort(event, property)

  return (
    <TableHead>
      <TableRow className={classes.tr}>
        {utilityIcon && (
          <TableCell padding="none" style={{ width: 10 }}>
            {utilityIcon}
          </TableCell>
        )}
        {headCells.map((headCell, index) => {
          const sortingActive = orderBy === headCell.id
          return (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              className={classNames(classes.tableHeadCell, headCell.disablePadding ? classes.tableHeadCellWithoutPadding : '', {
                [classes.paddingLeft]: headCell.paddingLeft,
                [classes.noPaddingLeft]: index === 0 && utilityIcon,
              })}
              style={{ ...headCell.style }}
              sortDirection={sortingActive ? order : false}
            >
              {headCell.sortable ? (
                <TableSortLabel
                  classes={{
                    active: classes.sortingActive,
                    icon: classes.sortingActive,
                  }}
                  active={sortingActive}
                  direction={sortingActive ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                </TableSortLabel>
              ) : (
                headCell.label
              )}
            </TableCell>
          )
        })}
      </TableRow>
    </TableHead>
  )
}

export default EnhancedTableHead
