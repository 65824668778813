import { ListItem, ListItemIcon, ListItemText, Theme } from '@mui/material';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

import makeStyles from '@mui/styles/makeStyles';

// TODO clean up
const useStyles = makeStyles((theme: Theme) => ({
  listItem: {
    cursor: 'pointer',
    paddingLeft: theme.spacing(1.5),
  },
  light: {
    '&:hover': {
      backgroundColor: '#e8e2f7',
      borderRadius: theme.shape.borderRadius,
      '& $icon': {
        color: theme.palette.primary.main,
      },
      '& $listItemText': {
        color: theme.palette.primary.main,
      },
      textDecoration: 'none',
    },
  },
  dark: {
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      borderRadius: theme.shape.borderRadius,
      '& $icon': {
        color: theme.palette.common.white,
      },
      '& $listItemText': {
        color: theme.palette.common.white,
      },
      textDecoration: 'none',
    },
  },
  dense: {
    '& + &': {
      marginTop: theme.spacing(),
    },
    paddingTop: theme.spacing(0.4),
    paddingBottom: theme.spacing(0.4),
  },
  normal: {
    '& + &': {
      marginTop: theme.spacing(),
    },
  },
  activeLight: {
    backgroundColor: '#e8e2f7',
    borderRadius: theme.shape.borderRadius,
    '& $listItemText': {
      color: theme.palette.primary.main,
    },
    '& $icon': {
      color: theme.palette.primary.main,
    },
  },
  activeDark: {
    backgroundColor: 'rgba(255, 255, 255, 0.06)',
    borderRadius: theme.shape.borderRadius,
    '& $listItemText': {
      color: theme.palette.common.white,
    },
    '& $icon': {
      color: theme.palette.common.white,
    },
  },
  listItemText: {
    color: theme.palette.text.secondary,
  },
  listItemIcon: {
    minWidth: 30,
  },
  icon: {},
}))

interface Props {
  path: string
  icon?: JSX.Element
  anchorText: string
  /** use light on dark backgrounds */
  onBackground?: 'light' | 'dark'
  variant?: 'dense' | 'normal'
  className?: string
}

const MenuLinkListItem = ({
  path,
  icon,
  anchorText,
  onBackground = 'dark',
  variant = 'normal',
  className,
}: Props) => {
  const classes = useStyles()

  const listItemClassName = classNames(
    {
      [classes.listItem]: true,
      [classes[variant]]: variant,
      [classes[onBackground]]: onBackground,
    },
    className
  )

  return (
    <ListItem
      activeClassName={onBackground === 'dark' ? classes.activeDark : classes.activeLight}
      className={listItemClassName}
      component={NavLink}
      exact
      to={path}
    >
      {icon && <ListItemIcon className={classes.listItemIcon}>{icon}</ListItemIcon>}
      <ListItemText slot="div" primary={anchorText} classes={{ primary: classes.listItemText }} />
    </ListItem>
  )
}

export default MenuLinkListItem
