import { Alert, TicketRevTextField } from 'components';
import { FormikErrors } from 'formik';
import { LoginProviderInfo } from '@seeka-labs-internal/lib-api-app';
import PasswordValidator from 'password-validator';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { api, queryKeys } from 'services';

import {
  Checkbox, Fade, FormControlLabel, FormGroup, Grid, IconButton, Typography
} from '@mui/material';
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';

import {
  FooterMessageRecaptchaEtc, LoginProvider, OrSeparator, PasswordCheckContainer,
  PasswordCheckLine, SubmitButton
} from '../components';
import { TermsAndPrivacyAgreement } from '../components/Terms';

export type FormProps = {
  email: string
  password: string
  tosAccepted: boolean
  firstName: string
  lastName: string
}

interface Props {
  loginProviders: LoginProviderInfo[]
  setFieldValue: (name: string, value: any) => void
  handleSubmit: () => void
  isSubmitting: boolean
  values: FormProps
  errors: FormikErrors<FormProps>
  withEmail?: boolean
}

const CreateAccountForm = ({
  loginProviders,
  setFieldValue,
  handleSubmit,
  isSubmitting,
  values,
  errors,
  withEmail = true,
}: Props) => {
  const { data: passwordOptionsData } = useQuery(queryKeys.passwordOptions, () =>
    api.account.getPasswordOptions()
  )

  const [showPassword, setShowPassword] = useState(false)
  const [initialValidation, setInitialValidation] = useState<string[]>([])
  const [validationRules, setValidationRules] = useState(new PasswordValidator())

  useEffect(() => {
    if (passwordOptionsData?.result && initialValidation.length === 0) {
      const { result: rules } = passwordOptionsData
      const validator = validationRules

      if (rules.requireDigit) validator.has().digits()
      if (rules.requireNonAlphanumeric) validator.has().symbols()
      if (rules.requireLowercase) validator.has().lowercase()
      if (rules.requireUppercase) validator.has().uppercase()
      if (rules.requiredLength) validator.min(rules.requiredLength)

      const getInitialValidationLength = validator.validate('', { list: true })

      setInitialValidation(
        (typeof getInitialValidationLength !== 'boolean' &&
          getInitialValidationLength) || ['']
      )
      setValidationRules(validator)
    }
  }, [passwordOptionsData])

  const passwordErrors = validationRules.validate(values.password, {
    list: true,
  }) as string[]

  const passwordValidationString = (rule: string | number) => {
    if (rule === 'min')
      return `Minimum ${passwordOptionsData?.result.requiredLength} characters`
    if (rule === 'uppercase') return 'Minimum 1 uppercase letter'
    if (rule === 'lowercase') return 'Minimum 1 lowercase letter'
    if (rule === 'digits') return 'Minimum 1 digit'
    if (rule === 'symbols') return 'Minimum 1 symbol'
    return ''
  }

  const showValidationHelp = passwordErrors.length > 0 && values.password.length > 0

  const handleTogglePasswordVisibility = () => setShowPassword(!showPassword)

  const facebookLoginProvider = loginProviders?.find((x) => x.name === 'Facebook')
  const googleLoginProvider = loginProviders?.find((x) => x.name === 'Google')

  return (
    <>
      <Grid container spacing={2} style={{ paddingBottom: 32 }}>
        <Grid item xs={6}>
          <LoginProvider
            provider="google"
            link={googleLoginProvider?.url || ''}
            disabled={isSubmitting || !googleLoginProvider}
            variant="create-account"
          />
        </Grid>
        <Grid item xs={6}>
          <LoginProvider
            provider="facebook"
            link={facebookLoginProvider?.url || ''}
            disabled={isSubmitting || !facebookLoginProvider}
            variant="create-account"
          />
        </Grid>
      </Grid>
      <OrSeparator />
      {errors.email && <Alert withMargin message={errors.email} severity="error" />}
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TicketRevTextField
              placeholder="First name"
              value={values.firstName}
              name="firstName"
              required
              label="First name"
              hideAsterix
              autoFocus
              disabled={isSubmitting}
              onChange={setFieldValue}
            />
          </Grid>
          <Grid item xs={6}>
            <TicketRevTextField
              placeholder="Last name"
              value={values.lastName}
              name="lastName"
              required
              label="Last name"
              hideAsterix
              disabled={isSubmitting}
              onChange={setFieldValue}
            />
          </Grid>
        </Grid>
        {withEmail && (
          <TicketRevTextField
            placeholder="Your work email"
            value={values.email}
            name="email"
            required
            type="email"
            label="Work email"
            hideAsterix
            disabled={isSubmitting}
            onChange={setFieldValue}
          />
        )}
        <TicketRevTextField
          type={showPassword ? 'text' : 'password'}
          required
          hideAsterix
          value={values.password}
          label="Create password"
          name="password"
          placeholder="Password"
          disabled={isSubmitting}
          onChange={setFieldValue}
          error={Boolean(errors.password)}
          endAdornment={
            <IconButton
              edge="end"
              disableFocusRipple
              size="small"
              disabled={isSubmitting}
              onClick={handleTogglePasswordVisibility}
            >
              {showPassword ? (
                <VisibilityOffOutlined style={{ color: '#000' }} />
              ) : (
                <VisibilityOutlined style={{ color: '#000' }} />
              )}
            </IconButton>
          }
        />
        <PasswordCheckContainer
          message={
            <Fade in={showValidationHelp} mountOnEnter unmountOnExit>
              <Typography variant="body2" color="error" align="center">
                {passwordValidationString(passwordErrors[0] || '')}
              </Typography>
            </Fade>
          }
        >
          {initialValidation
            .sort((a, b) => {
              return passwordErrors.indexOf(a) - passwordErrors.indexOf(b)
            })
            .map((x) => (
              <PasswordCheckLine
                key={x}
                active={!passwordErrors.includes(x)}
                paddingLeft
              />
            ))}
        </PasswordCheckContainer>
        <FormGroup style={{ marginBottom: 40, marginTop: 30 }}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={() => setFieldValue('tosAccepted', !values.tosAccepted)}
                checked={values.tosAccepted}
                color="primary"
              />
            }
            label={<TermsAndPrivacyAgreement description='signing up' />}
          />
        </FormGroup>
        <SubmitButton
          anchor="Create account"
          loading={isSubmitting}
          disabled={
            isSubmitting ||
            passwordErrors.length > 0 ||
            !values.email ||
            !values.tosAccepted ||
            !values.firstName ||
            !values.lastName
          }
        />
        <FooterMessageRecaptchaEtc />
      </form>
    </>
  )
}

export default CreateAccountForm
