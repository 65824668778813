import classNames from 'classnames'
import { Stack, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Skeleton } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.primary,
  },
  icon: {
    paddingTop: theme.spacing(),
    marginRight: theme.spacing(),
    color: theme.palette.text.secondary,
  },
  subtitle: {
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
  muted: {
    color: theme.palette.text.secondary,
  },
}))

interface Props {
  className?: string
  icon?: React.ReactNode
  title?: React.ReactNode
  subtitle?: string
  muted?: boolean
  loading?: boolean
}

const PaperTitle = ({
  className,
  icon,
  title,
  subtitle,
  muted,
  loading,
  ...rest
}: Props) => {
  const classes = useStyles()

  const rootClassName = classNames(
    [classes.root],
    {
      [classes.muted]: muted,
    },
    className
  )

  return (
    <div {...rest} className={rootClassName}>
      {icon && <span className={classes.icon}>{icon}</span>}
      <Stack gap={1}>
        {title && (
          <Typography variant="h3" color="inherit">
            {loading ? <Skeleton /> : title}
          </Typography>
        )}
        {subtitle && (
          <Typography className={classes.subtitle} variant="subtitle2" color="inherit">
            {subtitle}
          </Typography>
        )}
      </Stack>
    </div>
  )
}

export default PaperTitle
