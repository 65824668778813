import React from 'react'
import { Typography, Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginBottom: theme.spacing(3),
  },
  subtitle: {
    color: theme.palette.text.disabled,
    marginBottom: theme.spacing(),
  },
  title: {
    marginBottom: theme.spacing(1.5),
  },
  tagline: {
    lineHeight: '32px',
    marginBottom: theme.spacing(3.5),
  },
}))

interface Props {
  category?: string
  title: string
  subtitle?: string
}

const WizardTitle = ({ category, title, subtitle }: Props) => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      {category && (
        <Typography color="textSecondary" className={classes.subtitle}>
          <strong>{category}</strong>
        </Typography>
      )}
      <Typography variant="h1" className={classes.title}>
        {title}
      </Typography>
      {subtitle && (
        <Typography variant="body1" className={classes.tagline} color="textSecondary">
          {subtitle}
        </Typography>
      )}
    </div>
  )
}

export default WizardTitle
