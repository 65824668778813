import { SidebarMenu } from 'components'

import { List, Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import BottomSidebarContainer from '../BottomSidebarContainer'
import BrandSelect from '../BrandSelect'
import NavLinks from '../NavLinks'
import OrganisationSelect from '../OrganisationSelect'
import { useMe } from 'hooks'

const useStyles = makeStyles((theme: Theme) => ({
  topSection: {
    marginTop: theme.spacing(10),
  },
}))

interface Props {
  sidebarOpen: boolean
  setSidebarOpen: (sidebarOpen: boolean) => void
  isMobile: boolean
  orgName: string
  userGrants: string[]
  roleName: string
  slimMode: boolean
  handleReCaptchaVerify: () => Promise<string | undefined>
}

const Sidebar = ({
  sidebarOpen,
  setSidebarOpen,
  isMobile,
  orgName,
  userGrants,
  roleName,
  slimMode,
  handleReCaptchaVerify,
}: Props) => {
  const classes = useStyles()

  const handleClose = () => setSidebarOpen(false)

  const slimModeWidth = 75
  const sidebarWidth = slimMode ? (sidebarOpen && 210) || slimModeWidth : 210
  const hasBrands = true // userGrants.includes('seeka.Brands')

  const me = useMe()
  const isMultiBrandOrg = (me.data?.result.organisation.activeBrandsCount || 0) > 1

  return (
    <SidebarMenu
      open={sidebarOpen}
      onClose={handleClose}
      isMobile={isMobile}
      width={sidebarWidth}
    >
      <div className={classes.topSection}>
        <List component="nav" aria-label="Main Menu">
          {hasBrands ? (
            <BrandSelect
              orgName={orgName}
              slimMode={sidebarWidth === slimModeWidth}
              userGrants={userGrants}
            />
          ) : (
            <OrganisationSelect
              userGrants={userGrants}
              roleName={roleName}
              orgName={orgName}
              slimMode={sidebarWidth === slimModeWidth}
              handleReCaptchaVerify={handleReCaptchaVerify}
            />
          )}

          <NavLinks
            userGrants={userGrants}
            slimMode={sidebarWidth === slimModeWidth}
            isMultiBrandOrg={isMultiBrandOrg}
          />
        </List>
      </div>
      <BottomSidebarContainer
        userGrants={userGrants}
        slimMode={sidebarWidth === slimModeWidth}
      />
    </SidebarMenu>
  )
}

export default Sidebar
