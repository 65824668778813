import { StatusCircle } from 'components'
import React from 'react'

import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CircularProgress,
  Theme,
  Typography,
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

interface Props {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  title: string
  description: string
  icon?: JSX.Element
  backgroundColor?: string
  isConnected: boolean | undefined
  isLoading?: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  media: {
    height: 140,
  },
  iconContainer: {
    height: 140,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.common.white,
  },
  card: {
    boxShadow: '0 16px 64px -16px rgba(46,55,77,.1)',
  },
  cardActions: {
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(2),
  },
  status: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
  },
  active: {
    color: theme.palette.success.main,
  },
  inactive: {
    color: theme.palette.text.secondary,
  },
  content: {
    minHeight: 110,
  },
}))

const ConvergeCard = ({
  title,
  description,
  isConnected,
  backgroundColor,
  icon,
  onClick,
  isLoading = false,
}: Props) => {
  const classes = useStyles()

  return (
    <Card className={classes.card}>
      <CardActionArea onClick={onClick}>
        {icon && (
          <div className={classes.iconContainer} style={{ backgroundColor }}>
            {icon}
          </div>
        )}
        <CardContent className={classes.content}>
          <Typography gutterBottom variant="h5" component="h5">
            <strong>{title}</strong>
          </Typography>
          <Typography variant="body1" color="textSecondary" component="p">
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions className={classes.cardActions}>
        <Typography
          variant="body2"
          noWrap
          align="right"
          className={`${classes.status} ${
            isConnected ? classes.active : classes.inactive
          }`}
        >
          {isLoading ? (
            <CircularProgress size={20} />
          ) : (
            <>
              {isConnected ? (
                <>
                  <StatusCircle color="success" />
                  Connected
                </>
              ) : (
                <Box
                  style={{ visibility: isConnected === undefined ? 'hidden' : undefined }}
                >
                  <StatusCircle color="neutral" />
                  Not connected
                </Box>
              )}
            </>
          )}
        </Typography>
      </CardActions>
    </Card>
  )
}

export default ConvergeCard
