import React from 'react'
import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.common.white,
    width: '100%',
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(2),
    },
    height: '100%',
  },
  icon: {
    color: theme.palette.text.secondary,
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}))

interface Props {
  icon: JSX.Element
  children: JSX.Element | JSX.Element[]
}

const FilterContainer = ({ icon, children }: Props) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.icon}>{icon}</div>
      {children}
    </div>
  )
}

export default FilterContainer
