import SeekaLogo from 'assets/img/seeka-logo.svg';
import classNames from 'classnames';
import { memo } from 'react';

import { Hidden, IconButton, Theme, Toolbar } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Close as CloseIcon, Menu as MenuIcon } from '@mui/icons-material';

import UserAvatar from '../UserAvatar';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center',
    height: '67px',
    zIndex: theme.zIndex.appBar,
    position: 'fixed',
    borderBottom: `1px solid ${theme.palette.border}`,
    top: 0,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: '100%',
  },
  topbarShift: {
    width: '100%',
    zIndex: 1300,
  },
  toolbar: {
    minHeight: 'auto',
    width: '100%',
    justifyContent: 'space-between',
  },
  rightBox: {
    display: 'flex',
    alignItems: 'center',
  },
  leftBox: {
    paddingRight: theme.spacing(),
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    width: 116,
    marginLeft: -theme.spacing(0.5),
    display: 'block',
  },
  sidebarButton: {
    marginLeft: -theme.spacing(),
    marginRight: theme.spacing(2),
  },
}))

type ITopbarProps = {
  sidebarOpen: boolean
  setSidebarOpen?: (sidebarOpen: boolean) => void
  isMobile: boolean
  handleReCaptchaVerify: () => Promise<string | undefined>
  hideUserSettings?: boolean;
}

const Topbar = ({
  sidebarOpen,
  setSidebarOpen,
  isMobile,
  handleReCaptchaVerify,
  hideUserSettings
}: ITopbarProps) => {
  const classes = useStyles()

  const toggleSidebar = () => setSidebarOpen && setSidebarOpen(!sidebarOpen)

  return (
    (<div
      className={classNames(classes.root, {
        [classes.topbarShift]: !isMobile,
      })}
    >
      <Toolbar className={classes.toolbar}>
        <div className={classes.leftBox}>
          <Hidden smUp>
            <IconButton onClick={toggleSidebar} className={classes.sidebarButton} size="large">
              {sidebarOpen ? <CloseIcon /> : <MenuIcon />}
            </IconButton>
          </Hidden>
          <img src={SeekaLogo} alt="Seeka logo" className={classes.logo} />
        </div>
        <div className={classes.rightBox}>
          <UserAvatar handleReCaptchaVerify={handleReCaptchaVerify} hideUserSettings={hideUserSettings} />
        </div>
      </Toolbar>
    </div>)
  );
}

export default memo(Topbar)
