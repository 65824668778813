import { Typography } from '@mui/material'

const FooterMessageRecaptchaEtc = ({
  paddingTop = 40,
  align = 'left' as 'center' | 'left',
}) => {
  return (
    <Typography
      variant="body2"
      color="textSecondary"
      align={align}
      style={{ paddingTop }}
    >
      This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of
      Service apply.
    </Typography>
  )
}

export default FooterMessageRecaptchaEtc
