import { useQuery, UseQueryOptions } from 'react-query'
import { api } from 'services'
import {
  ApiResponseError,
  ApiResponseDtoOfCurrentLoginInfo,
} from '@seeka-labs-internal/lib-api-app'
import queryKeys from './queryKeys'

export const useMe = (
  options?: UseQueryOptions<
    ApiResponseDtoOfCurrentLoginInfo,
    ApiResponseError,
    ApiResponseDtoOfCurrentLoginInfo,
    typeof queryKeys.me
  >
) => {
  return useQuery(queryKeys.me, () => api.userProfile.getCurrentLoginInfo(), {
    ...options,
    refetchOnMount: false,
    retry: 1,
    staleTime: Infinity,
  })
}
