import React from 'react'
import { Theme, Divider } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  divider: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
  },
}))

const DropdownDivider = () => {
  const classes = useStyles()

  return <Divider className={classes.divider} />
}

export default DropdownDivider
