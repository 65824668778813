import React from 'react'
import classNames from 'classnames'
import { Paper, Theme, lighten, darken } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: theme.shape.borderRadius,
  },
  primary: {
    background: `linear-gradient(${theme.palette.primary.main}, ${lighten(
      theme.palette.primary.main,
      0.3
    )})`,
  },
  error: {
    background: `linear-gradient(${theme.palette.error.main}, ${darken(
      theme.palette.error.main,
      0.2
    )})`,
  },
  maxHeight: { height: '100%' },
  transparent: {
    backgroundColor: 'transparent',
  },
}))

interface Props {
  children: React.ReactNode
  className?: string
  color?: string
  maxHeight?: boolean
}

const CustomPaper = ({ children, className, color = '', maxHeight = false }: Props) => {
  const classes = useStyles()

  const rootClassName = classNames(
    {
      [classes.root]: true,
      [classes.maxHeight]: maxHeight,
      [classes.primary]: color === 'primary',
      [classes.error]: color === 'error',
      [classes.transparent]: color === 'transparent',
    },
    className
  )

  return (
    <Paper className={rootClassName} elevation={0} component="div" square={false}>
      {children}
    </Paper>
  )
}

export default CustomPaper
