export default {
  root: {
    minWidth: 72,
    textTransform: 'none',
    fontWeight: 500,
    borderRadius: '1.4375rem',
    variants: [],
  },
  label: {
    textTransform: 'initial',
  },
  sizeSmall: {
    // textTransform: 'none',
  },
  /* contained: {
    backgroundColor: palette.common.white,
    '&:hover': {
      backgroundColor: palette.common.neutral,
    },
  }, */
}
