import React, { useState } from 'react'
import { Typography, Theme, Card, CardActionArea, CardContent, Fade } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames'
import { Skeleton } from '@mui/material';

interface Props {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  title?: React.ReactNode
  description?: React.ReactNode
  selected?: boolean
  icon?: React.ReactNode
  loading?: boolean
  disabled?: boolean
  hoverDescription?: React.ReactNode
  className?: string
  skeleton?: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    boxShadow: 'none',
    overflow: 'hidden',
    backgroundColor: 'transparent',
  },
  disabled: {
    opacity: 0.7,
  },
  content: {
    textAlign: 'center',
  },
  selected: {
    backgroundColor: theme.palette.primary.light,
  },
  title: {
    marginTop: theme.spacing(),
  },
}))

const ChannelCard = ({
  title,
  description,
  selected,
  icon,
  onClick,
  loading,
  disabled,
  hoverDescription,
  className,
  skeleton,
}: Props) => {
  const [showHoverDesc, setShowHoverDesc] = useState(false)
  const classes = useStyles()

  const root = classNames(
    [classes.root],
    {
      [classes.disabled]: disabled,
      [classes.selected]: selected,
    },
    className
  )

  const handleShowHoverDesc = () => setShowHoverDesc(true)
  const handleHideHoverDesc = () => setShowHoverDesc(false)

  return skeleton ? (
    <Card className={root} style={{ width: '100%', height: '100%' }}>
      <CardContent className={classes.content} style={{ width: '100%', height: '100%' }}>
        <Skeleton animation="wave" width="100%" height="100%" variant="rectangular" />
      </CardContent>
    </Card>
  ) : (
    <Card
      className={root}
      onMouseEnter={
        (Boolean(hoverDescription && !loading) && handleShowHoverDesc) || undefined
      }
      onMouseLeave={
        (Boolean(hoverDescription && !loading) && handleHideHoverDesc) || undefined
      }
    >
      <CardActionArea onClick={onClick} disabled={disabled || loading}>
        <CardContent className={classes.content}>
          {icon}
          <Typography variant="h5" gutterBottom noWrap className={classes.title}>
            {loading ? <Skeleton variant="text" /> : <strong>{title}</strong>}
          </Typography>
          {showHoverDesc && (
            <Fade in mountOnEnter unmountOnExit>
              <Typography color="textSecondary" variant="body2">
                {hoverDescription}
              </Typography>
            </Fade>
          )}
          {!showHoverDesc && (
            <Fade in mountOnEnter unmountOnExit>
              <div>
                {description && typeof description === 'string' ? (
                  <Typography color="textSecondary" variant="body2">
                    {loading ? <Skeleton variant="text" /> : description}
                  </Typography>
                ) : (
                  description
                )}
              </div>
            </Fade>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default ChannelCard
