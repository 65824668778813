import { ChevronLeft as ChevronLeftIcon, MoreHoriz as MoreIcon } from '@mui/icons-material';
import {
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import { memo } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  list: {
    padding: '0px',
  },
  listMargin: {
    marginLeft: theme.spacing(3),
  },
  backAnchor: {
    marginLeft: -theme.spacing(0.5),
    fontSize: theme.typography.body2.fontSize,
  },
  backListItem: {
    padding: 0,
    marginTop: theme.spacing(),
  },
}))

interface Props {
  goBack: () => void
  loading?: boolean
  handleMoreOnClick?: () => void
  backAnchor: string
  withMargin?: boolean
}

const DrawerTopBar = ({
  goBack,
  loading,
  backAnchor,
  handleMoreOnClick,
  withMargin = true,
}: Props) => {
  const classes = useStyles()

  const listClassName = classNames([classes.list], {
    [classes.listMargin]: withMargin,
  })

  return (
    (<List className={listClassName}>
      <ListItem disableGutters className={classes.backListItem}>
        <ListItemIcon>
          <IconButton edge="start" onClick={goBack} size="large">
            <ChevronLeftIcon />
          </IconButton>
        </ListItemIcon>
        <ListItemText slot="div"
          secondary={backAnchor}
          classes={{
            secondary: classes.backAnchor,
          }}
        />
        {!loading && Boolean(handleMoreOnClick) && (
          <ListItemSecondaryAction>
            <IconButton edge="end" onClick={handleMoreOnClick} size="large">
              <MoreIcon />
            </IconButton>
          </ListItemSecondaryAction>
        )}
      </ListItem>
    </List>)
  );
}

export default memo(DrawerTopBar)
