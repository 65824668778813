import React from 'react'
import classNames from 'classnames'
import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'inline-flex',
    borderRadius: '50%',
    alignSelf: 'center',
    verticalAlign: 'middle',
    // flexGrow: 0,
    // flexShrink: 0,
    marginRight: 6,
  },
  sm: {
    height: theme.spacing(1),
    width: theme.spacing(1),
  },
  md: {
    height: theme.spacing(2),
    width: theme.spacing(2),
  },
  lg: {
    height: theme.spacing(3),
    width: theme.spacing(3),
  },
  neutral: {
    backgroundColor: theme.palette.text.secondary,
  },
  primary: {
    backgroundColor: theme.palette.primary.main,
  },
  info: {
    backgroundColor: theme.palette.info.main,
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
  },
  error: {
    backgroundColor: theme.palette.error.main,
  },
  success: {
    backgroundColor: theme.palette.success.main,
  },
}))

interface Props {
  className?: string
  size?: 'sm' | 'md' | 'lg'
  color?: 'neutral' | 'primary' | 'info' | 'warning' | 'error' | 'success'
  customColor?: string
}

const StatusCircle = ({
  className,
  size = 'sm',
  color = 'primary',
  customColor,
}: Props) => {
  const classes = useStyles()

  const rootClassName = classNames(
    {
      [classes.root]: true,
      [classes[size]]: size,
      [classes[color]]: color,
    },
    className
  )

  return <span className={rootClassName} style={{ backgroundColor: customColor }} />
}

export default StatusCircle
