import { lazy } from 'react';
import { RouteProps } from 'utils';
import LogStreamTable from 'views/Settings/Diagnostics/LogStream';

import SvgIcon from '@mui/material/SvgIcon';
import {
    Camera as HealthIcon, CategoryRounded as ChannelsIcon, FlareRounded as ConvergeIcon,
    PermMediaRounded as ContentLibraryIcon, SendRounded as BroadcastsIcon,
    SettingsInputAntenna as SignalsIcon, SettingsRounded as SettingsIcon,
    TrackChanges as AcquisitionIcon
} from '@mui/icons-material';

const Settings = lazy(() => import('views/Settings'))
const Plans = lazy(() => import('views/Plans'))
const SignalsDashboard = lazy(() => import('views/Converge/Signals'))
const AcquisitionDashboard = lazy(() => import('views/Converge/Acquisition'))
const HealthDashboard = lazy(() => import('views/Converge/Health'))
const Setup = lazy(() => import('views/Setup'))
const CreateOrganisation = lazy(() => import('views/Settings/CreateOrganisation'))
const Subscribe = lazy(() => import('views/Subscribe'))

export interface AuthenticatedRouteProps {
  key: string
  title: string
  path: string
  rootPath: string
  matchPath: string
  requiresOneOf: string[]
  component: (props: RouteProps) => JSX.Element
  hasLayout: boolean
  disableForMultiBrandOrg?: boolean
  slimSideBar: boolean
  showInSidebar: boolean
  icon: typeof SvgIcon
  containerWidth?: number
}

export default [  
  {
    key: 'ViewDiagnosticsStream',
    title: 'Diagnostics',
    path: `settings/developer/diagnostics/stream`,
    rootPath: 'settings',
    matchPath: `/settings/developer/diagnostics/stream`,
    requiresOneOf: ['module.Converge.Read'],
    component: LogStreamTable,
    hasLayout: false,
    showInSidebar: false,
    slimSideBar: false,
    icon: ConvergeIcon,
  },
  {
    key: 'ViewSettings',
    title: 'Settings',
    path: `settings/:category?/:setting?/:action?/:anotherAction?/:andAnotherAction?`,
    rootPath: 'settings',
    matchPath: `/settings/:category?/:setting?/:action?/:anotherAction?/:andAnotherAction?`,
    // requiresOneOf: ['settingManagement.Enable'],
    component: Settings,
    hasLayout: true,
    showInSidebar: false,
    slimSideBar: false,
    icon: SettingsIcon,
  },
  {
    key: 'ViewPlans',
    title: 'Plans',
    path: `plans`,
    rootPath: 'plans',
    matchPath: `/plans`,
    component: Plans,
    hasLayout: false,
    showInSidebar: false,
    slimSideBar: true,
  },
  {
    key: 'ViewCreateOrganisation',
    title: 'Create organisation',
    path: `create-organisation`,
    rootPath: 'create-organisation',
    matchPath: `/create-organisation`,
    // requiresOneOf: ['settingManagement.Enable'],
    component: CreateOrganisation,
    hasLayout: false,
    showInSidebar: false,
    slimSideBar: false,
    icon: SettingsIcon,
  },
  {
    key: 'SetupConverge',
    title: 'Set-up',
    path: `setup/:category?/:item?`,
    rootPath: 'setup',
    matchPath: `/setup/:category?/:item?`,
    requiresOneOf: ['module.Converge.Manage'],
    component: Setup,
    hasLayout: false,
    showInSidebar: false,
    slimSideBar: false,
    icon: ConvergeIcon,
  },
  {
    key: 'SignalsDashboard',
    title: 'Signals',
    path: `dashboard/signals`,
    rootPath: 'dashboard/signals',
    matchPath: `/dashboard/signals/:subpage?`,
    requiresOneOf: ['module.Converge.Read'],
    component: SignalsDashboard,
    hasLayout: true,
    showInSidebar: true,
    slimSideBar: false,
    icon: SignalsIcon,
    containerWidth: 2400,
  },
  {
    key: 'AcquisitionDashboard',
    title: 'Acquisition',
    path: `dashboard/acquisition`,
    rootPath: 'dashboard/acquisition',
    matchPath: `/dashboard/acquisition`,
    requiresOneOf: ['module.Converge.Read'],
    component: AcquisitionDashboard,
    hasLayout: true,
    disableForMultiBrandOrg: true,
    showInSidebar: true,
    slimSideBar: false,
    icon: AcquisitionIcon,
    containerWidth: 2400,
  },
  {
    key: 'HealthDashboard',
    title: 'Health',
    path: `dashboard/health`,
    rootPath: 'dashboard/health',
    matchPath: `/dashboard/health`,
    // requiresOneOf: ['module.Converge.Read'],
    requiresOneOf: [`_Core.Internal.StaffMember`],
    component: HealthDashboard,
    hasLayout: true,
    showInSidebar: true,
    slimSideBar: false,
    icon: HealthIcon,
    containerWidth: 2400,
  },
  {
    key: 'Subscribe',
    title: 'Subscribe',
    path: `subscribe`,
    matchPath: `/subscribe/:subpage?`,
    component: Subscribe,
    hasLayout: false,
    showInSidebar: false,
    slimSideBar: false,
  },
] as AuthenticatedRouteProps[]
