import { createTheme, responsiveFontSizes, adaptV4Theme } from '@mui/material/styles';

import overrides from './overrides';
import palette from './palette';
import shape from './shape';
import typography from './typography';

interface BrandObject {
  backgroundColor: string
  color: string
}
interface ConvergeObject {
  backgroundColor: string
  color: string
}
interface ConvergeDestinationObject {
  backgroundColor: string
  color: string
}
interface Gradient {
  blue: string
  red: string
  purple: string
}
export interface Brand {
  facebook: BrandObject
  google: BrandObject
  sms: BrandObject
  email: BrandObject
  omnichannel: BrandObject
  wallet: BrandObject
  messenger: BrandObject
  ticketrev: BrandObject
  manychat: BrandObject
  gatsby: BrandObject
  magento: BrandObject
  zenGlobal: BrandObject
  webhook: BrandObject
  wait: BrandObject
  goal: BrandObject
  stage: BrandObject
  note: BrandObject
  conditionalSplit: BrandObject
  oneTimeCampaign: BrandObject
  automatedCampaign: BrandObject
  enrichmentCampaign: BrandObject
  acquisitionCampaign: BrandObject
  outboundDirection: BrandObject
  inboundDirection: BrandObject
  omniDirection: BrandObject
}

export interface Converge {
  shopify: ConvergeObject
  oztix: ConvergeObject
  megatix: ConvergeObject
  woocommerce: ConvergeObject
  zapier: ConvergeObject
  manychat: ConvergeObject
  gatsby: ConvergeObject
  magento: ConvergeObject
  bigcommerce: ConvergeObject
  wordpress: ConvergeObject
  scriptTag: ConvergeObject
  iframe: ConvergeObject
  seekaApp: ConvergeObject
  goHiLevel: ConvergeObject
}

export interface ConvergeDestination {
  facebook: ConvergeDestinationObject
  googleAds: ConvergeDestinationObject
  googleAnalytics: ConvergeDestinationObject
  snapchat: ConvergeDestinationObject
  pinterest: ConvergeDestinationObject
  tiktok: ConvergeDestinationObject
}

export interface PipelineIntegration {
  klaviyo: ConvergeDestinationObject
  loke: ConvergeDestinationObject
}

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    warning: PaletteColor
    success: PaletteColor
    error: PaletteColor
    info: PaletteColor
    gradient: Gradient
    brand: Brand
    converge: Converge
    convergeDestination: ConvergeDestination
    pipelineIntegration: PipelineIntegration
    border: string
    divider: string
  }
  interface PaletteOptions {
    warning?: PaletteColorOptions
    success?: PaletteColorOptions
    error?: PaletteColorOptions
    info?: PaletteColorOptions
  }
}

/* declare module '@mui/material/styles/createMuiTheme' {
  interface Theme {
    border: string
    divider: string
  }
  interface DeprecatedThemeOptions {
    border?: string
    divider?: string
  }
} */

const theme = createTheme(adaptV4Theme({
  palette,
  typography,
  overrides: {
    ...overrides,
    MuiAlert: {
      standardError: {
        backgroundColor: palette.error.light,
        color: palette.text.primary
      },
      standardWarning: {
        backgroundColor: palette.warning.light,
        color: palette.text.primary
      },
      standardSuccess: {
        backgroundColor: palette.success.light,
        color: palette.text.primary
      },
      standardInfo: {
        backgroundColor: palette.info.light,
        color: palette.text.primary
      },
    },
    MuiChip: {
      colorError: palette.error.light,
      colorWarning: palette.warning.light,
      colorInfo: palette.info.light,
      colorSuccess: palette.success.light,
    }
  },
  shape,
  props: {    
    MuiLink: {
      underline: 'none',
    },
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiButton: {
      disableElevation: true,
    }
  },
}))

export default responsiveFontSizes(theme)
