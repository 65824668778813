import seekaBotParcelSmile from 'assets/img/seeka_bot_parcel_smile.svg';

import { Box, Container, Divider, Grid, Typography } from '@mui/material';

type Props = {
    title: string
    content: JSX.Element
}

export const ErrorSplashContainer = ({ title, content }: Props) => {
    return (
        <Container style={{ paddingTop: '100px' }} maxWidth='md'>
            <Grid container spacing={3}
                alignItems="center"
                justifyContent="center">
                <Grid item xs={12} sm={12} md={6}>
                    <Typography variant="h1" component="h1" style={{ marginBottom: '30px' }}>{title}</Typography>
                    {content}
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <img src={seekaBotParcelSmile} style={{ height: '300px' }} alt="seeka bot" />
                </Grid>
            </Grid>
        </Container>
    )
}