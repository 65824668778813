import React from 'react'
import classNames from 'classnames'
import { Typography, Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  caption: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
}))

interface Props {
  children: JSX.Element
  className?: string
}

const PaperCaption = ({ children, className }: Props) => {
  const classes = useStyles()
  const rootClassName = classNames(classes.caption, className)

  return (
    <Typography variant="body1" className={rootClassName}>
      {children}
    </Typography>
  )
}

export default PaperCaption
