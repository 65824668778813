import React, { useState, useRef, useEffect } from 'react'
import { Organisation } from '@seeka-labs-internal/lib-api-app'
import { useQueryClient } from 'react-query'
import { useSnackbar } from 'notistack'
import { TicketRevListItem, Alert } from 'components'
import { Theme, TextField, InputAdornment, Divider, IconButton, Typography, Slide } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { getToken, setToken, useDebounce, useIntersectionObserver } from 'utils'
import { useInfiniteOrganisations } from 'hooks'
import { api } from 'services'
import {
  LoyaltyRounded as BrandIcon,
  AddRounded as AddIcon,
  Search as SearchIcon,
  KeyboardBackspaceRounded as BackIcon,
} from '@mui/icons-material'
import loginManager from 'services/loginManager'
import useShopifyAppInstallContext from 'utils/useShopifyAppInstallContext'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    overflow: 'hidden',
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
  },
  backContainer: {
    display: 'flex',
    color: theme.palette.text.primary,
    marginLeft: 6,
    alignItems: 'center',
    marginBottom: theme.spacing(0.5),
  },
  title: {
    marginLeft: theme.spacing(1.3),
  },
  paper: {
    overflow: 'visible',
    width: 284,
    [theme.breakpoints.down(600)]: {
      width: '100%',
    },
    paddingLeft: 0,
    paddingRight: 0,
  },
  searchContainer: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(1.5),
  },
  brandsContainer: {
    maxHeight: 398,
    overflow: 'auto',
    paddingTop: theme.spacing(1.5),
  },
  createContainer: {
    marginTop: theme.spacing(),
  },
  inputRoot: {
    backgroundColor: theme.palette.common.white,
  },
  divider: {},
  alert: {
    marginBottom: theme.spacing(1.5),
  },
}))

interface Props {
  selectedOrganisation: Organisation
  handleClose: () => void
  goToPage: (page: string) => void
  handleCloseDropdown: () => void
  handleReCaptchaVerify: () => Promise<string | undefined>
}

const SwitchOrganisation = ({
  selectedOrganisation,
  handleClose,
  goToPage,
  handleCloseDropdown,
  handleReCaptchaVerify,
}: Props) => {
  const queryClient = useQueryClient()
  const classes = useStyles()
  const [searchString, setSearchString] = useState('')
  const [debouncedUpdate, setDebouncedUpdate] = useState('')
  const [debouncedSearchString] = useDebounce(searchString, 350)
  const [loading, setLoading] = useState('')
  const { enqueueSnackbar } = useSnackbar()

  const shopifyInstallContext = useShopifyAppInstallContext();
  const { hasContext } = shopifyInstallContext;
  const history = useHistory()

  useEffect(() => {
    setDebouncedUpdate(searchString)
  }, [debouncedSearchString])

  const {
    data,
    error,
    isFetchingNextPage,
    isFetching,
    fetchNextPage,
    hasNextPage,
    isLoading,
  } = useInfiniteOrganisations({
    searchString: debouncedUpdate,
    pageSize: 100,
  })
  const flattenedOrganisations =
    data?.pages
      .map((x) => x.result)
      .flat()
      .filter((x) => x.organisation.id !== selectedOrganisation.id) || []

  const loadMoreContainerRef = useRef<HTMLDivElement>(null)

  useIntersectionObserver({
    target: loadMoreContainerRef,
    onIntersect: fetchNextPage,
    enabled: Boolean(hasNextPage),
  })

  const handleSearchChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    event.stopPropagation()
    setSearchString(event.target.value)
  }

  const switchOrganisation = async (id: string, recaptchaToken: string) => {
    setLoading(id)
    try {
      const newToken = await loginManager.getTokenForOrgOrThrow(
        getToken() as string,
        id,
        recaptchaToken
      )
      setLoading('')
      setToken(newToken.accessToken)
      queryClient.invalidateQueries()
      enqueueSnackbar(
        `Switched to ${flattenedOrganisations.find((x) => x.organisation.id === id)?.organisation
          .description
        }`,
        {
          variant: 'success',
        }
      )
      handleCloseDropdown()
      if (hasContext) {
        // Redirect to Shopify install as it is pending
        history.push('/setup/sources/shopify');
      }
    } catch (err) {
      setLoading('')
      const responseError = { ...err }
      enqueueSnackbar(responseError?.error?.message || 'An error occurred', {
        variant: 'error',
      })
    }
  }

  return (
    (<Slide in direction="left" timeout={{ appear: 350, enter: 200, exit: 0 }}>
      <div className={classes.paper}>
        <div className={classes.backContainer}>
          <IconButton color="inherit" onClick={handleClose} size="large">
            <BackIcon color="inherit" />
          </IconButton>
          <Typography variant="h4" className={classes.title}>
            <strong>Select organisation</strong>
          </Typography>
        </div>
        <div className={classes.container}>
          <div className={classes.searchContainer}>
            <TextField
              onChange={handleSearchChange}
              fullWidth
              variant="outlined"
              size="small"
              value={searchString}
              autoFocus
              slotProps={{
                input: {
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  classes: {
                    root: classes.inputRoot,
                  },
                }
              }}
            />
          </div>
          <Divider className={classes.divider} />
          <div className={classes.brandsContainer}>
            {isFetching && flattenedOrganisations.length === 0 && (
              <>
                <TicketRevListItem size="small" listItemSize="smallListItem" skeleton />
                <TicketRevListItem size="small" listItemSize="smallListItem" skeleton />
                <TicketRevListItem size="small" listItemSize="smallListItem" skeleton />
                <div style={{ marginBottom: 16 }} />
              </>
            )}
            {error && (
              <Alert
                severity="error"
                message={error?.error?.message || 'An error occurred'}
                className={classes.alert}
              />
            )}
            {!isFetching &&
              !isLoading &&
              !searchString &&
              flattenedOrganisations.length === 0 && (
                <Alert
                  severity="info"
                  message="You don't have any other organisations available"
                  className={classes.alert}
                />
              )}
            {searchString && !isLoading && flattenedOrganisations.length === 0 && (
              <Alert severity="info" message="No results" className={classes.alert} />
            )}
            {flattenedOrganisations.map((x, index) => {
              return (
                <TicketRevListItem
                  key={x.organisation.id}
                  primaryText={x.organisation.description}
                  secondaryText={x.assignedUserRoleNames.join(', ')}
                  icon={<BrandIcon />}
                  onClick={async () =>
                    switchOrganisation(
                      x.organisation.id,
                      (await handleReCaptchaVerify()) as string
                    )
                  }
                  size="small"
                  listItemSize="smallListItem"
                  loading={loading === x.organisation.id}
                  noWrap
                  disabled={Boolean(
                    selectedOrganisation.id === x.organisation.id || loading
                  )}
                  marginBottom={index === flattenedOrganisations.length - 1 ? 12 : 8}
                />
              )
            })}
            {isFetchingNextPage && (
              <TicketRevListItem size="small" listItemSize="smallListItem" skeleton />
            )}
            <div ref={loadMoreContainerRef} style={{ width: '100%', height: 1 }} />
          </div>
          <Divider className={classes.divider} />
          <div className={classes.createContainer}>
            <TicketRevListItem
              primaryText="Create organisation"
              icon={<AddIcon />}
              onClick={() => goToPage('create-organisation')}
              size="small"
              listItemSize="smallListItem"
            />
          </div>
        </div>
      </div>
    </Slide>)
  );
}

export default SwitchOrganisation
