import React, { useMemo, useState } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from '@mui/material'
import { getComparator, stableSort } from 'utils/sorting'
import TableHead from './TableHead'

export type Order = 'asc' | 'desc'

export interface HeadCell {
  disablePadding: boolean
  id: string
  label?: string
  numeric: boolean
  paddingLeft: boolean
  sortable: boolean
  style?: React.CSSProperties
}

interface Props {
  data: any[]
  headCells: HeadCell[]
  initialOrderBy: string
  page: number
  setPage: (newPage: number) => void
  getRow: (rowData: any) => JSX.Element
  labelRowsPerPage?: string
}


export const defaultRowsPerPage = 10

const EnhancedTable = ({
  data,
  headCells,
  initialOrderBy,
  page,
  setPage,
  getRow,
  labelRowsPerPage = 'Rows per page:',
}: Props) => {
  const [order, setOrder] = useState<Order>('asc')
  const [orderBy, setOrderBy] = useState<string>(initialOrderBy)
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage)
  const rowsLength = data.length

  const handleRequestSort = (_event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value))
    setPage(0)
  }

  // Distinct
  const rowsPerPageOptionsVal = useMemo(() => {
    return [defaultRowsPerPage, 10, 15, 25].filter((value, index, self) => self.indexOf(value) === index)
  }, [defaultRowsPerPage])
  
  return (<>
    <TableContainer>
      <Table aria-labelledby="table" aria-label="enhanced table">
        <TableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          headCells={headCells}
        />
        <TableBody>
          {stableSort(data, getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row) => getRow(row))}
          {rowsLength === 0 && (
            <TableRow>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
    <TablePagination
      rowsPerPageOptions={rowsPerPageOptionsVal}
      component="div"
      labelRowsPerPage={labelRowsPerPage}
      count={rowsLength}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  </>);
}

export default EnhancedTable
