import { RouteComponentProps, useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import loginManager from 'services/loginManager'
import { setToken } from 'utils'
import { LoginProviderInfo } from '@seeka-labs-internal/lib-api-app'
import LoginForm, { FormProps } from '../../Login/Form'

interface Props extends Partial<RouteComponentProps> {
  loginProviders: LoginProviderInfo[]
  getRecaptchaToken: () => Promise<string | undefined>
  inviteEmail: string
  onSuccess: () => void
}

const LoginAndAccept = ({
  loginProviders,
  getRecaptchaToken,
  inviteEmail,
  onSuccess,
}: Props) => {
  const initialValues: FormProps = {
    email: inviteEmail,
    password: '',
  }

  const history = useHistory();

  const {
    setFieldValue,
    handleSubmit,
    values,
    isSubmitting,
    setSubmitting,
    errors,
    setErrors,
  } = useFormik({
    initialValues,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (payload) => {
      setSubmitting(true)
      try {
        const recaptchaToken = await getRecaptchaToken()
        if (!recaptchaToken) return
        const loginOrgId = await loginManager.getOrgForLogin(payload.email)
        // no account, shouldn't happen here but let's be safe
        if (!loginOrgId) {
          setErrors({
            email: `We couldn't find an account associated with ${payload.email}.`,
          })
          setSubmitting(false)
        } else {
          try {
            const loginResponse = await loginManager.loginOrThrow(
              payload.email,
              payload.password,
              loginOrgId,
              recaptchaToken
            )
            setToken(loginResponse.accessToken)
            // will remove this component and show "Accept invitation" button in parent component
            onSuccess()
          } catch (reason) {
            const errorToObject: any = reason ? { ...reason } : undefined
            setErrors({
              password:
                ('body' in errorToObject &&
                  'error_description' in errorToObject.body &&
                  errorToObject.body.error_description) ||
                'Password is incorrect. Please try again.',
            })
            setSubmitting(false)
          }
        }
      } catch (error) {
        setErrors({
          email: `An error occurred`,
        })
        setSubmitting(false)
      }
    },
  })

  const handleGoToResetPassword = () => history?.push('/account-recovery')

  const setFieldValueWrapper = (name: string, value: any) => {
    setFieldValue(name, value)
    // eslint-disable-next-line
    if (errors.hasOwnProperty(name)) {
      setErrors({ ...errors, [name]: undefined })
    }
  }

  return (
    <LoginForm
      setFieldValue={setFieldValueWrapper}
      handleGoToResetPassword={handleGoToResetPassword}
      loginProviders={loginProviders}
      isLoading={isSubmitting}
      handleSubmit={handleSubmit}
      values={values}
      errors={errors}
      withEmail={false}
    />
  )
}

export default LoginAndAccept
