import { DialogWrapper, TicketRevListItem } from 'components'
import { FormikErrors } from 'formik'
import { UserGrantedOrganisation } from '@seeka-labs-internal/lib-api-account'
import { useState } from 'react'
import loginManager from 'services/loginManager'
import { setToken } from 'utils'

import { Avatar, Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    cursor: 'pointer',
    marginTop: 6,
    marginLeft: 2,
    marginBottom: theme.spacing(1.5),
    display: 'block',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  avatar: {
    backgroundColor: theme.palette.common.black,
  },
}))

interface Props {
  email: string
  password: string
  organisations: UserGrantedOrganisation[]
  getRecaptchaToken: () => Promise<string | undefined>
  handleClose: () => void
  setErrors: (
    errors: FormikErrors<{
      email: string
      password: string
    }>
  ) => void
}

const SelectOrganisationDialog = ({
  email,
  password,
  organisations,
  setErrors,
  handleClose,
  getRecaptchaToken,
}: Props) => {
  const classes = useStyles()
  const [loading, setLoading] = useState<string | null>()

  const handleLogin = async (orgId: string) => {
    setLoading(orgId)
    try {
      const recaptchaToken = await getRecaptchaToken()
      if (!recaptchaToken) return
      const loginResponse = await loginManager.loginOrThrow(
        email,
        password,
        orgId,
        recaptchaToken
      )
      setToken(loginResponse.accessToken)
      setLoading(null)
      handleClose()
    } catch (reason) {
      const errorToObject: any = reason ? { ...reason } : undefined
      setErrors({
        password:
          ('body' in errorToObject &&
            'error_description' in errorToObject.body &&
            errorToObject.body.error_description) ||
          ('body' in errorToObject &&
            'error' in errorToObject.body &&
            errorToObject.body.error.message) ||
          'Password is incorrect. Please try again.',
      })
      setLoading(null)
      handleClose()
    }
  }

  return (
    <DialogWrapper
      open
      onClose={handleClose}
      title="Select organisation"
      withForm={false}
      preventBackdropClickClose
      disableEscapeKeyDown
      maxWidth="xs"
    >
      <div
        style={{
          marginBottom: 20,
          marginLeft: -6,
          marginRight: -8,
          maxHeight: '300px',
          overflowY: 'auto',
        }}
      >
        {organisations.map((x) => {
          return (
            <TicketRevListItem
              key={x.organisation.id}
              primaryText={x.organisation.description}
              size="small"
              listItemSize="smallListItem"
              secondaryText="Click to select"
              noWrap
              icon={
                <Avatar className={classes.avatar}>
                  {x.organisation.description.charAt(0).toUpperCase()}
                </Avatar>
              }
              onClick={() => handleLogin(x.organisation.id)}
              loading={loading === x.organisation.id}
              disabled={Boolean(loading)}
              marginBottom={8}
            />
          )
        })}
      </div>
    </DialogWrapper>
  )
}

export default SelectOrganisationDialog
