import React from 'react'
import { Theme, Breadcrumbs, Chip, Box, Fade } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { ChevronLeft as ChevronLeftIcon } from '@mui/icons-material'
import { Skeleton } from '@mui/material';

const StyledBreadcrumb = withStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: 500,
    '&:hover, &:focus': {
      backgroundColor: '#ececec',
      boxShadow: 'none',
    },
    '&:active': {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(Chip) as typeof Chip

interface LabelProps {
  label: string
  url: string
  show?: boolean
}

interface Props {
  handleClose: () => void
  changeRoute: (route?: string) => void
  resolved: boolean
  labels: LabelProps[]
}

const DrawerBreadcrumbs = ({ handleClose, changeRoute, labels, resolved }: Props) => {
  const close = (event: React.MouseEvent<Element, MouseEvent>) => {
    event.preventDefault()
    handleClose()
  }

  const handleChangeRoute = (
    event: React.MouseEvent<Element, MouseEvent>,
    route: string
  ) => {
    event.preventDefault()
    changeRoute(route)
  }

  const handlePreventDefaultClick = (event: React.MouseEvent<Element, MouseEvent>) =>
    event.preventDefault()

  return (
    <Box paddingTop={2} paddingBottom={1}>
      <Breadcrumbs aria-label="breadcrumb">
        {(!('show' in labels[0]) || labels[0].show) && (
          <StyledBreadcrumb
            component="a"
            href="#"
            label={labels[0].label}
            icon={<ChevronLeftIcon fontSize="small" />}
            onClick={close}
          />
        )}
        {resolved ? (
          <Fade in={Boolean(labels && labels[1])}>
            <StyledBreadcrumb
              component="a"
              href="#"
              label={labels[1].label}
              onClick={(event: React.MouseEvent<Element, MouseEvent>) =>
                handleChangeRoute(event, labels[1].url)
              }
            />
          </Fade>
        ) : (
          <Skeleton variant="text" width={100} />
        )}
        {labels.map((x, index) => {
          return index > 1 && x.show ? (
            <Fade in={x.show} key={x.label} mountOnEnter unmountOnExit>
              <StyledBreadcrumb
                component="a"
                href="#"
                label={x.label}
                onClick={handlePreventDefaultClick}
              />
            </Fade>
          ) : null
        })}
      </Breadcrumbs>
    </Box>
  )
}

export default DrawerBreadcrumbs
