import { Typography, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import SeekaLogo from 'assets/img/seeka-logo.svg'
import SeekaBotFistPump from 'assets/img/seeka_bot_fist_pump.svg'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: 'fit-content',
    minHeight: '100%',
    width: '30%',
    maxWidth: 660,
    display: 'flex',
    backgroundColor: '#EBECF4',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: theme.spacing(8),
    [theme.breakpoints.down(1500)]: {
      padding: theme.spacing(6),
    },
    [theme.breakpoints.down(1350)]: {
      padding: theme.spacing(4),
    },
  },
  logo: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(5),
    width: '100%',
    maxWidth: 268,
  },
  seekaBotFistPump: {
    marginLeft: '11vw',
    minWidth: '17vw',
    marginBottom: '50px',
    [theme.breakpoints.down(2300)]: {
      marginLeft: '12vw',
      minWidth: '21vw',
    },
  },
}))

const BrandingSidebar = () => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <div>
        <img src={SeekaLogo} alt="Seeka logo" className={classes.logo} />
        <Typography variant="h1">
          Built for performance marketers, designed by performance marketers.
        </Typography>
      </div>
      <img src={SeekaBotFistPump} alt="Seeka Bot" className={classes.seekaBotFistPump} />
    </div>
  )
}

export default BrandingSidebar
