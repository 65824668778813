import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react'

const useStyles = makeStyles((theme: Theme) => ({
  loginContainer: {
    display: 'flex',
    height: '100%',
    backgroundColor: theme.palette.background.default,
    // flexDirection: 'column',
  },
}))

const Container = ({ children }: { children: React.ReactNode }) => {
  const classes = useStyles()

  return <div className={classes.loginContainer}>{children}</div>
}

export default Container
