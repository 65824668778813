import { PaperLoader } from 'components'
import config from 'config'
import AppContext, { getLastVisitedBrand, removeLastVisitedBrand } from 'context'
import { useBrand, useDefaultBrand, useInfiniteBrands, useMe } from 'hooks'
import { useCallback, useEffect, useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useQueryClient } from 'react-query'
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'
import { UserRole } from 'types'
import { getToken, removeToken } from 'utils'
import useShopifyAppInstallContext from 'utils/useShopifyAppInstallContext'
import Account from 'views/Account'
import LoginProviderCallback from 'views/Account/LoginProviderCallback'
import CreateOrganisation from 'views/Settings/CreateOrganisation'
import { errorKeys, ErrorSplash } from 'views/Settings/ErrorSplash'

import { Theme, useMediaQuery } from '@mui/material'
import { useSeekaConverge } from '@seeka-labs/converge-react'

import { setCookieItem } from 'utils/cookie'
import accountRoutes from './accountRoutes'
import AuthenticatedMe from './AuthenticatedMe'

const Router = () => {
  const queryClient = useQueryClient()
  const location = useLocation()
  const history = useHistory()
  const { executeRecaptcha } = useGoogleReCaptcha()
  const handleReCaptchaVerify = useCallback(async () => {
    if (executeRecaptcha) {
      const token = await executeRecaptcha(
        location.pathname.replace(/\//g, '').replace(/-/g, '')
      )
      return token
    }
    return undefined
  }, [executeRecaptcha])

  const { pathname } = location
  const queryParams = new URLSearchParams(location.search)
  const inviteToken = queryParams.get('it')
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'), {
    noSsr: true,
  })

  const selectedPlanId = queryParams.get('planId')
  if (selectedPlanId) {
    sessionStorage.setItem('seeka_selected_plan', selectedPlanId)
  }

  // Stores state relating to connecting a Shopify store
  useShopifyAppInstallContext()

  const isAudienceBareboneView = pathname.startsWith('/x/')
  const isAcceptingInvitation =
    Boolean(inviteToken) || pathname.startsWith(accountRoutes.acceptInvitation)
  const token = getToken()
  const intercom = useIntercom()

  const converge = useSeekaConverge()

  useEffect(() => {
    if (location.search && location.search.search(/partner/gi)) {
      const partnerCode = queryParams.get('partner')
      if (partnerCode) {
        setCookieItem('seeka_partner_key', partnerCode, 30)
      }
    }
  }, [location.search])

  const [lastLocation, setLastLocation] = useState<string | null>(null)

  useEffect(() => {
    if (!isMobile) {
      intercom.update({
        hideDefaultLauncher: false,
        alignment: 'right',
      })
    } else {
      intercom.update({
        hideDefaultLauncher: true,
      })
    }
  }, [isMobile])

  useEffect(() => {
    if (lastLocation === null || lastLocation !== location.pathname) {
      converge?.track.viewPage()
      setLastLocation(location.pathname)
    }
  }, [])

  useEffect(() => {
    if (lastLocation === null || lastLocation !== location.pathname) {
      converge?.track.viewPage()
      setLastLocation(location.pathname)
    }
  }, [location])

  const handleLogout = () => {
    removeToken()
    queryClient.clear()

    if (config.intercomAppId) {
      try {
        intercom.update({
          email: undefined,
          name: undefined,
          userHash: undefined,
          userId: undefined,
          phone: undefined,
          company: undefined,
        })
        // intercom.hardShutdown();
      } catch (err) {
        console.error(err)
      }
    }

    converge?.track.custom('WebAppLogout')
    history.push(`/sign-in`)
  }

  const { data, error, isLoading } = useMe({
    enabled: Boolean(token && !isAudienceBareboneView),
    onError: isAcceptingInvitation ? undefined : () => handleLogout(),
  })

  useEffect(() => {
    // Log user out if /me endpoint returns null on profile
    if (
      data?.result.profile === null &&
      !isAcceptingInvitation &&
      !isAudienceBareboneView
    )
      handleLogout()
  }, [data])

  const hasAccessToBrands = Boolean(token) && data && data.result.config.auth // Boolean(
  //   data && data.result.config.auth.grantedPolicies['seeka.Brands'] === true
  // )

  const brandsPromise = useInfiniteBrands(
    {
      searchString: '',
      pageSize: 2,
    },
    Boolean(token)
  )

  const brandsOnAccount: boolean = (brandsPromise.data?.pages[0].totalCount || 0) > 0

  const lastVisitedBrand = getLastVisitedBrand()
  const enabled = Boolean(hasAccessToBrands && !lastVisitedBrand && brandsOnAccount)
  const { data: defaultBrandData, error: defaultBrandError } = useDefaultBrand({
    enabled,
  })
  const { data: lastVisitedBrandData, error: lastVisitedBrandError } = useBrand(
    lastVisitedBrand || '',
    {
      enabled: Boolean(hasAccessToBrands && lastVisitedBrand),
      retry: false,
    }
  )
  const [selectedBrand, setSelectedBrand] = useState({
    id: '',
    name: '',
  })
  const handleChangeBrand = (brandId: string, brandName: string) => {
    setSelectedBrand({ ...selectedBrand, id: brandId, name: brandName })
  }

  const selectedBrandData = useBrand(selectedBrand.id)

  useEffect(() => {
    if (lastVisitedBrandData || defaultBrandData) {
      setSelectedBrand({
        id: lastVisitedBrandData?.result.id || defaultBrandData?.result.id || '',
        name:
          lastVisitedBrandData?.result.description ||
          defaultBrandData?.result.description ||
          '',
      })
    }
  }, [defaultBrandData, lastVisitedBrandData])

  useEffect(() => {
    // in case ID in local storage doesn't work, trigger fetch of default brand
    if (lastVisitedBrandError) {
      removeLastVisitedBrand()
      setSelectedBrand({ ...selectedBrand })
    }
  }, [lastVisitedBrandError])

  // Check if an org and/or tenant has been requested to be selected via URL params - used by backend and redirect urls
  // const urlOrgId = queryParams.get('orgId');
  // const urlBrandId = queryParams.get('brandId');

  const isNotAuthenticated = Boolean(!token || error)

  // useEffect(() => {
  //   try {
  //     intercom.boot({

  //     });
  //     (window as any).FrontChat('init', {
  //       chatId: '31a6cd1f6dec5f0888dc91b6a7a7315d',
  //       useDefaultLauncher: true
  //     });
  //   } catch (err) {
  //     console.error(err)
  //   }
  // }, [])

  useEffect(() => {
    if (config.intercomAppId) {
      if (
        isNotAuthenticated ||
        !selectedBrand ||
        !data ||
        !selectedBrandData.data?.result ||
        !selectedBrandData.data.result.intercomUserHash
      ) {
        intercom.update({
          email: undefined,
          name: undefined,
          userHash: undefined,
          userId: undefined,
          phone: undefined,
          company: undefined,
        })
      } else {
        const { config } = data.result
        const { currentUser } = config

        intercom.update({
          email: currentUser.email,
          name: `${currentUser.name} ${currentUser.surName || ''}`.trim(),
          userHash: selectedBrandData.data.result.intercomUserHash,
          userId: selectedBrandData.data.result.intercomUserId,
          phone: currentUser.phoneNumber,
          company: {
            companyId: selectedBrand.id,
          },
        })
      }
    }
  }, [selectedBrand, isNotAuthenticated, selectedBrandData])

  if (isAcceptingInvitation) {
    return (
      <Switch>
        <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
        <Route component={Account} exact path={accountRoutes.acceptInvitation} />
        <Redirect to={`${accountRoutes.acceptInvitation}${location.search}`} />
      </Switch>
    )
  }

  if (selectedPlanId && isNotAuthenticated && (pathname === '' || pathname === '/')) {
    // Redirect to sign up page
    return <Redirect to={`/sign-up?planId=${selectedPlanId}`} />
  }

  if (selectedPlanId && !isNotAuthenticated && (pathname === '' || pathname === '/')) {
    // Redirect to plan selection page if user is authenticated
    return <Redirect to={`/subscribe/confirm${location.search}`} />
  }

  if (isNotAuthenticated) {
    return (
      <Switch>
        <Route
          component={LoginProviderCallback}
          exact
          path={accountRoutes.loginProviderCallback}
        />
        <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
        {Object.values(accountRoutes)
          .filter((x) => x !== accountRoutes.loginProviderCallback)
          .map((x) => {
            return <Route component={Account} exact path={x} key={x} />
          })}
        <Redirect to={accountRoutes.login} />
      </Switch>
    )
  }

  const isOnboarding =
    data &&
    data.result &&
    data.result.organisation &&
    !brandsOnAccount &&
    !brandsPromise.isLoading &&
    data.result.organisation.activeBrandsCount === 0
  if (isOnboarding) {
    return (
      <Switch>
        <Route component={CreateOrganisation} path="/create-organisation" />
        <Redirect to="/create-organisation?organisationModifier=update" />
      </Switch>
    )
  }
  const isUserBrandRestrictedAndNoAccessToAnyBrands =
    data &&
    data.result &&
    data.result.organisation &&
    !brandsOnAccount &&
    !brandsPromise.isLoading &&
    data.result.organisation.activeBrandsCount > 0
  if (isUserBrandRestrictedAndNoAccessToAnyBrands) {
    return (
      <Switch>
        <Route component={ErrorSplash} path="/error/splash" />
        <Redirect to={`/error/splash?err=${errorKeys.NoBrandAuthError}`} />
      </Switch>
    )
  }

  // when switching org, me will load
  if (isLoading) return <PaperLoader />

  if (defaultBrandError) return <h1>{defaultBrandError.result}</h1>

  // if brands role, resolve brands data first and set in context
  if ((data && !hasAccessToBrands) || (data && hasAccessToBrands && selectedBrand.id)) {
    const { config, profile, organisation } = data.result
    const { currentUser } = config

    // Create string array out of roles that are true.
    const userGrants = Object.keys(config.auth.grantedPolicies).filter(
      (key) => config.auth.grantedPolicies[key] === true
    )

    if (organisation) {
      return (
        <AppContext.Provider
          value={{
            selectedBrand,
            changeBrand: handleChangeBrand,
            userGrants,
            userRole: currentUser.roles[0] as UserRole,
          }}
        >
          <AuthenticatedMe
            // TODO remove all this stuff and restructure
            organisation={organisation}
            roles={userGrants}
            roleName={currentUser.roles[0]}
            handleReCaptchaVerify={handleReCaptchaVerify}
          />
        </AppContext.Provider>
      )
    }
  }

  return <PaperLoader />
}

export default Router
