import formatCurrency from "./formatCurrency"

const convertMonthlySessionsToHumanReadable = (
  monthlySessions: number | undefined,
  chargePerSession: number | undefined
): string => {
  if (monthlySessions && monthlySessions !== 1) {
    return `Includes ${monthlySessions / 1000}k sessions / month`
  }
  return !chargePerSession ? `Unlimited sessions` : `Plus ${formatCurrency(chargePerSession)} per session`
}

export default convertMonthlySessionsToHumanReadable
