import React, { useState } from 'react'
import classNames from 'classnames'
import { Theme, Grid, Button, Typography, Menu, MenuItem } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Pagination } from '@mui/material';
import { PaginationProps } from '@mui/lab';
import { ArrowDropDownRounded as DropdownIcon } from '@mui/icons-material'
import { defaultRowsPerPage } from '../Table';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: theme.spacing(),
  },
  count: {
    display: 'block',
    marginLeft: theme.spacing(),
  },
  menuItem: {
    margin: 0,
    minWidth: 100,
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
  },
  button: {
    marginTop: -2,
    marginLeft: -theme.spacing(0.6),
  },
}))

interface Props {
  rowsPerPage?: number
  page: number
  totalCount?: number
  setPage: (newPage: number) => void
  setRowsPerPage: (newRows: number) => void
  rowsPerPageOptions?: number[]
  pageFromZero?: boolean
  size?: PaginationProps['size']
  className?: string
}

const TablePagination = ({
  rowsPerPage,
  page,
  totalCount = 0,
  setPage,
  setRowsPerPage,
  rowsPerPageOptions = [defaultRowsPerPage, 25, 50],
  pageFromZero = true,
  size = 'large',
  className,
}: Props) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const rowsPerPageVal = rowsPerPage || defaultRowsPerPage

  const pageCount = Math.ceil(totalCount / rowsPerPageVal)

  const containerClassName = classNames([classes.container], className)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => setAnchorEl(null)

  const handleChange = (_event: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(pageFromZero ? newPage - 1 : newPage)
  }

  const handleSetRowsPerPage = (newValue: number) => {
    setAnchorEl(null)
    setRowsPerPage(newValue)
  }

  const pageAdjust = pageFromZero ? page : page - 1

  const showingTo = (pageAdjust * rowsPerPageVal) + rowsPerPageVal
  const showingToLabel = showingTo >= totalCount ? totalCount : showingTo

  return (
    <>
      <Grid container justifyContent="space-between" className={containerClassName}>
        <div>
          <Button
            variant="text"
            color="primary"
            onClick={handleClick}
            endIcon={<DropdownIcon />}
            className={size === 'large' ? classes.button : ''}
            size={size === 'large' ? 'medium' : 'small'}
          >
            {rowsPerPage} rows per page
          </Button>
          {totalCount > 0 && (
            <Typography variant="caption" className={classes.count}>
              Showing {((pageAdjust * rowsPerPageVal) + (page === 0 ? 1 : 0)).toLocaleString()}-
              {showingToLabel.toLocaleString()} of {totalCount?.toLocaleString()}
            </Typography>
          )}
        </div>
        <Pagination
          count={pageCount}
          page={pageFromZero ? page + 1 : page}
          shape="rounded"
          size={size}
          onChange={handleChange}
        />
      </Grid>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {rowsPerPageOptions.map((x) => {
          return (
            <MenuItem
              key={x}
              onClick={() => handleSetRowsPerPage(x)}
              className={classes.menuItem}
              disabled={rowsPerPage === x}
            >
              {x}
            </MenuItem>
          )
        })}
      </Menu>
    </>
  )
}

export default TablePagination
