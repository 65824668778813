import React from 'react'
import { IconButton, Tooltip, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { HelpOutline as HelpIcon } from '@mui/icons-material'

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    color: theme.palette.success.main,
  },
  iconButton: {
    padding: 4,
    marginRight: 6,
    backgroundColor: theme.palette.success.light,
  },
}))

const SupportIcon = ({ tooltip = 'Help' }) => {
  const classes = useStyles()

  return (
    (<Tooltip title={tooltip} arrow>
      <IconButton className={classes.iconButton} size="large">
        <HelpIcon className={classes.icon} />
      </IconButton>
    </Tooltip>)
  );
}

export default SupportIcon
