import {
  ApiResponseDtoOfOrganisationBrand, ApiResponseDtoOfOrganisationBrandWithUserContext,
  ApiResponseError, ItemListResponseOfOrganisationBrand
} from '@seeka-labs-internal/lib-api-app';
import { useInfiniteQuery, useQuery, UseQueryOptions } from 'react-query';
import { api } from 'services';

import queryKeys, { BrandsFilterProps } from './queryKeys';

export const useInfiniteBrands = (filters: BrandsFilterProps, enabled?: boolean) => {
  return useInfiniteQuery<
    ItemListResponseOfOrganisationBrand,
    ApiResponseError,
    ItemListResponseOfOrganisationBrand,
    ReturnType<typeof queryKeys.brandsPage>
  >(
    queryKeys.brandsPage(filters),
    ({ pageParam = 0 }) =>
      api.org.brand.getAllOrganisationBrands(
        filters.searchString,
        undefined,
        pageParam * filters.pageSize,
        filters.pageSize
      ),
    {
      getNextPageParam: (lastPage, allPages) => {
        const flattenedPagesLength = allPages.map((x) => x.result).flat().length
        const currentPage = flattenedPagesLength / filters.pageSize - 1
        const hasNextPage = currentPage < lastPage.totalCount / filters.pageSize - 1
        return hasNextPage ? currentPage + 1 : undefined
      },
      enabled,
      staleTime: 300000,
    }
  )
}

export const useBrand = (
  id: string,
  options?: UseQueryOptions<
    ApiResponseDtoOfOrganisationBrandWithUserContext,
    ApiResponseError,
    ApiResponseDtoOfOrganisationBrandWithUserContext,
    ReturnType<typeof queryKeys.singleBrand>
  >
) => {
  return useQuery(queryKeys.singleBrand(id), () => api.org.brand.getSingle(id), {
    ...options,
    staleTime: 300000,
  })
}

export const useDefaultBrand = (
  options?: UseQueryOptions<
    ApiResponseDtoOfOrganisationBrand,
    ApiResponseError,
    ApiResponseDtoOfOrganisationBrand,
    typeof queryKeys.default
  >
) => {
  return useQuery(queryKeys.default, () => api.org.brand.getDefault(), {
    ...options,
    staleTime: 300000,
  })
}
