import { Link, Typography } from '@mui/material';

type Props = {
    description: string
}
export const TermsAndPrivacyAgreement = ({ description }: Props) => {

    return (
        <Typography variant="body1" component="div">
            By {description}, I accept the <Link
                underline="none"
                target="_blank"
                rel="noopener"
                color="inherit"
                href="https://seeka.co/terms"
                style={{ fontWeight: 'bold' }}
            >
                Terms of Service
            </Link> and acknowledge the <Link
                underline="none"
                target="_blank"
                rel="noopener"
                color="inherit"
                style={{ fontWeight: 'bold' }}
                href="https://seeka.co/privacy"
            >
                Privacy Policy
            </Link>.
        </Typography>
    )
}