import {
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Theme,
  Typography,
} from '@mui/material';
import classNames from 'classnames';
import { Fragment, useState } from 'react';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  box: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
  },
  primary: {
    fontWeight: 600,
    paddingTop: 1,
  },
  listItem: {
    padding: theme.spacing(1.5),
    paddingTop: theme.spacing(0.7),
    paddingBottom: theme.spacing(0.7),
    marginBottom: theme.spacing(0.3),
    marginTop: theme.spacing(0.3),
    transition: theme.transitions.create('padding', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shortest,
    }),
    '&:hover': {
      backgroundColor: theme.palette.background.default,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  container: {
    width: 'auto',
    flex: '1 1 auto',
  },
  listItemText: {
    flex: 'unset',
    width: '100%',
    maxWidth: '30%',
  },
  moreContainer: {
    marginTop: theme.spacing(),
  },
  link: {
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.8,
    },
  },
}))

interface Data {
  leftCell?: number | string
  middleCell?: number | string
  rightCell?: number | string
  color: string
  label: string
}

interface Props {
  data: Data[]
  showMoreMax?: number
  showMoreLabel?: string
  className?: string
  showMoreMessage?: string
  dense?: boolean
  hasShowMore?: boolean
  noDataMessage?: string
  listItemClassName?: string
}

const StatsTable = ({
  data,
  showMoreMax = 5,
  hasShowMore = true,
  showMoreLabel = 'Show all',
  showMoreMessage,
  dense = false,
  noDataMessage = 'No data',
  className,
  listItemClassName,
}: Props) => {
  const classes = useStyles()
  const [internalMax, setInternalMax] = useState(showMoreMax)

  const handleSetMax = () => {
    setInternalMax(data.length)
  }

  const rootClassName = classNames(
    {
      [classes.root]: true,
    },
    className
  )

  const listItemClassNameCombined = classNames([classes.listItem], listItemClassName)

  return (
    <List dense={dense} disablePadding className={rootClassName}>
      {data.length > 0 ? (
        data.map((x, index, array) => {
          return (
            <Fragment key={`${x.color}${x.label}`}>
              <ListItem disableGutters className={listItemClassNameCombined}>
                <ListItemIcon>
                  <span className={classes.box} style={{ backgroundColor: x.color }} />
                </ListItemIcon>
                <ListItemText slot="div"
                  primary={x.label}
                  classes={{ primary: classes.primary }}
                  className={classes.listItemText}
                />
                <Grid
                  container
                  justifyContent="flex-end"
                  spacing={1}
                  className={classes.container}
                >
                  {x.leftCell && (
                    <Grid item xs={4}>
                      <Typography color="textSecondary" noWrap align="right">
                        {x.leftCell}
                      </Typography>
                    </Grid>
                  )}
                  {x.middleCell && (
                    <Grid item xs={4}>
                      <Typography color="textSecondary" noWrap align="right">
                        {x.middleCell}
                      </Typography>
                    </Grid>
                  )}
                  {x.rightCell && (
                    <Grid item xs={4}>
                      <Typography noWrap align="right">
                        <strong>{x.rightCell}</strong>
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </ListItem>
              {index !== array.length - 1 && <Divider />}
            </Fragment>
          )
        })
      ) : (
        <ListItem disableGutters className={classes.listItem}>
          <ListItemText slot="div" primary={noDataMessage} classes={{ primary: classes.primary }} />
        </ListItem>
      )}
      {data.length > 0 && internalMax !== data.length && hasShowMore && (
        <div className={classes.moreContainer}>
          <Typography
            variant="body2"
            color="textSecondary"
            display="inline"
            component="span"
          >
            <strong>{data.length - internalMax}</strong> {showMoreMessage}{' '}
            <Typography
              color="primary"
              display="inline"
              variant="body2"
              onClick={handleSetMax}
              className={classes.link}
            >
              {showMoreLabel}
            </Typography>
          </Typography>
        </div>
      )}
    </List>
  )
}

export default StatsTable
