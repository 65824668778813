import { defaultTableState as verifiedDomainsTableState } from 'views/Settings/VerifiedDomains'

// https://react-query.tanstack.com/guides/query-keys
export default {
  me: ['me'],
  brands: ['brands'],
  shopifyStores: (brandId: string) => ['shopifyStores', brandId],
  dataDestinations: ['dataDestinations'],
  segmentConditionFields: ['segmentConditionFields'],
  textTokens: (walletPassInstallEmailTemplate?: boolean) => [
    'textTokens',
    walletPassInstallEmailTemplate,
  ],
  timezones: ['timezones'],
  countries: ['countries'],
  passwordOptions: ['passwordOptions'],
  loginProviders: (redirectUrl?: string, inviteUrl?: string) => [
    'loginProviders',
    redirectUrl,
    inviteUrl,
  ],
  organisations: ['organisations'],
  teamMembers: ['teamMembers'],
  teamInvites: ['teamInvites'],
  userRoles: ['userRoles'],
  verifiedDomains: (props: typeof verifiedDomainsTableState) => [
    'verifiedDomains',
    Object.values(props),
  ],
  allVerifiedDomains: ['verifiedDomains'],
  allContentLibrary: ['contentLibrary'],
}
