import React from 'react'
import classNames from 'classnames'
import { IconButton, Tooltip, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { HelpOutline as HelpIcon } from '@mui/icons-material'
import useButtonStyles from 'theme/hooks/useButtonStyles'

const useStyles = makeStyles((theme: Theme) => ({
  small: {
    padding: theme.spacing(0.5),
  },
  large: {
    padding: theme.spacing(1.8),
  },
  removeHover: {
    '&:hover': {
      cursor: 'default',
    },
  },
}))

interface Props {
  className?: string
  tooltip?: string
  icon?: JSX.Element
  size?: 'small' | 'large'
  color?: 'success' | 'warning' | 'primary' | 'error'
}

const HighlightedIcon = ({
  className,
  tooltip = '',
  size = 'small',
  icon = <HelpIcon />,
  color = 'success',
}: Props) => {
  const classes = useStyles()
  const buttons = useButtonStyles()

  const iconButton = classNames({
    [classes[size]]: true, // nice
    [classes.removeHover]: tooltip.length < 1,
    [buttons.success]: color === 'success',
    [buttons.warning]: color === 'warning',
    [buttons.primary]: color === 'primary',
    [buttons.error]: color === 'error',
    className,
  })

  return (
    (<Tooltip title={tooltip} arrow>
      <IconButton className={iconButton} size="large">{icon}</IconButton>
    </Tooltip>)
  );
}

export default HighlightedIcon
