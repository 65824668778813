import React from 'react'
import { Theme, Typography, Divider } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  divider: {
    marginTop: -theme.spacing(),
    marginBottom: theme.spacing(2),
  },
  separationDot: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  link: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
      opacity: 0.8,
    },
  },
}))

interface Props {
  firstAnchor: string
  firstLink: () => void
  secondAnchor?: string
  secondLink?: () => void
  disabled?: boolean
}

const Footer = ({
  firstAnchor,
  firstLink,
  secondAnchor,
  secondLink,
  disabled,
}: Props) => {
  const classes = useStyles()

  return (
    <>
      <Divider className={classes.divider} />
      <div
        style={{
          display: 'flex',
          justifyContent: secondAnchor ? 'space-between' : 'center',
        }}
      >
        <Typography
          color="primary"
          display="inline"
          variant="body2"
          className={classes.link}
          onClick={disabled ? undefined : firstLink}
        >
          {firstAnchor}
        </Typography>
        {secondAnchor && secondLink && (
          <Typography
            color="primary"
            display="inline"
            variant="body2"
            className={classes.link}
            onClick={disabled ? undefined : secondLink}
          >
            {secondAnchor}
          </Typography>
        )}
      </div>
    </>
  )
}

export default Footer
