import {
  ApiException, ApiResponseDtoOfAppBillingInvoiceListResponse,
  ApiResponseDtoOfAppBillingPlanWithPrice, ApiResponseDtoOfBoolean,
  ApiResponseDtoOfListResultDtoOfAppBillingPaymentMethod,
  ApiResponseDtoOfListResultDtoOfAppBillingPlan, ApiResponseDtoOfOrganisationBrandBillingStatus,
  ApiResponseError, AppBillingPlanOfferingType, AppBillingPlanPaymentFrequency
} from '@seeka-labs-internal/lib-api-app';
import { useQuery, UseQueryOptions } from 'react-query';
import { api } from 'services';

import queryKeys from './queryKeys';

export const useBillingPlans = (
  options?: UseQueryOptions<
    ApiResponseDtoOfListResultDtoOfAppBillingPlan,
    ApiException,
    ApiResponseDtoOfListResultDtoOfAppBillingPlan,
    typeof queryKeys.billingPlans
  >
) => {
  return useQuery(queryKeys.billingPlans, () => api.billing.listCurrentPlans(), {
    ...options,
    retry: 1,
  })
}

export const useRecommendedPlan = (
  monthlySessions: number,
  offering: AppBillingPlanOfferingType,
  frequency: AppBillingPlanPaymentFrequency,
  options?: UseQueryOptions<
    ApiResponseDtoOfAppBillingPlanWithPrice,
    ApiResponseError,
    ApiResponseDtoOfAppBillingPlanWithPrice,
    typeof queryKeys.billingPlans
  >
) => {
  return useQuery(
    queryKeys.recommendedPlan(monthlySessions.toString(), offering, frequency),
    () => api.billing.getRecommendedPlan(monthlySessions, offering, frequency),
    {
      ...options,
    }
  )
}

export const useBillingStatus = (
  organisationBrandId: string,
  options?: UseQueryOptions<
    ApiResponseDtoOfOrganisationBrandBillingStatus,
    ApiException,
    ApiResponseDtoOfOrganisationBrandBillingStatus,
    typeof queryKeys.billingPlans
  >
) => {
  return useQuery(
    queryKeys.billingStatus(organisationBrandId),
    () => api.billing.getBillingStatus(organisationBrandId),
    {
      ...options,
    }
  )
}

export const useCanExtendTrial = (
  organisationBrandId: string,
  options?: UseQueryOptions<
    ApiResponseDtoOfBoolean,
    ApiException,
    ApiResponseDtoOfBoolean,
    typeof queryKeys.billingPlans
  >
) => {
  return useQuery(
    queryKeys.canExtendTrial(organisationBrandId),
    () => api.billing.canExtendTrial(organisationBrandId),
    {
      ...options,
    }
  )
}

export const usePaymentMethods = (
  organisationBrandId: string,
  options?: UseQueryOptions<
    ApiResponseDtoOfListResultDtoOfAppBillingPaymentMethod,
    ApiException,
    ApiResponseDtoOfListResultDtoOfAppBillingPaymentMethod,
    typeof queryKeys.billingPlans
  >
) => {
  return useQuery(
    queryKeys.paymentMethods(organisationBrandId),
    () => api.billing.getPaymentMethods(organisationBrandId),
    {
      ...options,
      retry: false,
    }
  )
}

export const useListInvoices = (
  organisationBrandId: string,
  pageSize: number,
  pageToken: string | undefined,
  options?: UseQueryOptions<
    ApiResponseDtoOfAppBillingInvoiceListResponse,
    ApiException,
    ApiResponseDtoOfAppBillingInvoiceListResponse,
    typeof queryKeys.billingPlans
  >
) => {
  return useQuery(
    queryKeys.listInvoices(organisationBrandId, pageSize, pageToken),
    () => api.billing.listInvoices(organisationBrandId, pageToken, pageSize),
    {
      ...options,
      retry: false,
    }
  )
}
