import { useCallback } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useLocation } from 'react-router-dom';
import Topbar from 'views/Layout/components/Topbar';

import { Theme, useMediaQuery } from '@mui/material';

import { NoBrandAuthError } from './components/NoBrandAuthError';

export const errorKeys = {
  NoBrandAuthError: 'auth.brand.none',
}

export const ErrorSplash = () => {
  const location = useLocation()
  const { executeRecaptcha } = useGoogleReCaptcha()
  const handleReCaptchaVerify = useCallback(async () => {
    if (executeRecaptcha) {
      const token = await executeRecaptcha(
        location.pathname.replace(/\//g, '').replace(/-/g, '')
      )
      return token
    }
    return undefined
  }, [executeRecaptcha])

  const queryParams = new URLSearchParams(location.search)
  const errorKey = queryParams.get('err')

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'), {
    noSsr: true,
  })

  return (
    <>
      <Topbar hideUserSettings isMobile={isMobile} sidebarOpen={false} handleReCaptchaVerify={handleReCaptchaVerify} />
      {
        errorKey === errorKeys.NoBrandAuthError && (
          <NoBrandAuthError />
        )
      }
    </>
  )
}