import React from 'react'
import classNames from 'classnames'
import { Typography, Theme, TypographyProps } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  withMargin: {
    marginBottom: theme.spacing(2),
  },
  inputMargin: {
    marginBottom: theme.spacing(2.5),
    display: 'block',
  },
}))

interface Props extends TypographyProps {
  children: JSX.Element | string | number
  inputMargin?: boolean
  withMargin?: boolean
  component?: any
}

const TextBlock = ({
  children,
  withMargin = true,
  inputMargin = false,
  component,
  ...rest
}: Props) => {
  const classes = useStyles()

  const root = classNames({
    [classes.withMargin]: withMargin,
    [classes.inputMargin]: inputMargin,
  })

  // bad fix, but having a ts propblem with "component"
  return (
    <>
      {component ? (
        <Typography component={component} className={root} {...rest}>
          {children}
        </Typography>
      ) : (
        <Typography className={root} {...rest}>
          {children}
        </Typography>
      )}
    </>
  )
}

export default TextBlock
