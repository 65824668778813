import { AppBar } from 'components';
import { useFormik } from 'formik';
import { PropertyValidationError } from '@seeka-labs-internal/lib-api-account';
import { LoginProviderInfo } from '@seeka-labs-internal/lib-api-app';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { api } from 'services';
import { useDocumentTitle } from 'utils';

import { Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { BrandingSidebar, Container, ContentCard, Title } from '../components';
import Form, { FormProps } from './Form';

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    cursor: 'pointer',
    marginTop: 6,
    marginLeft: 2,
    marginBottom: theme.spacing(1.5),
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  absoluteLinkContainer: {
    position: 'absolute',
    right: 44,
    top: 30,
    zIndex: 999,
  },
}))

const initialValues: FormProps = {
  email: '',
  firstName: '',
  lastName: '',
  password: '',
  tosAccepted: false,
}

interface Props extends RouteComponentProps {
  loginProviders: LoginProviderInfo[]
  /** used to remove invite token on route change in case of error */
  hasLoginProvidersError: boolean
  getRecaptchaToken: () => Promise<string | undefined>
}

const CreateAccount = ({
  loginProviders,
  hasLoginProvidersError,
  getRecaptchaToken,
}: Props) => {
  const history = useHistory();
  useDocumentTitle('Sign up to Seeka')
  const classes = useStyles()
  const theme = useTheme()
  const { enqueueSnackbar } = useSnackbar()
  const showAppBar = useMediaQuery(theme.breakpoints.down(1000), { noSsr: true })

  const handleGoToLogin = () => {
    history.push(`/sign-in${hasLoginProvidersError ? '' : history.location.search}`)
  }

  const {
    setFieldValue,
    handleSubmit,
    values,
    setSubmitting,
    isSubmitting,
    errors,
    setErrors,
  } = useFormik({
    initialValues,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (payload) => {
      try {
        const recaptchaToken = await getRecaptchaToken()
        if (!recaptchaToken) return
        const response = await api.account.triggerEmailVerification(
          payload.email,
          recaptchaToken
        )
        history.push({
          pathname: '/verify-email',
          state: {
            firstName: payload.firstName,
            lastName: payload.lastName,
            email: payload.email,
            password: payload.password,
            emailVerificationProcessId: response.result,
          },
        })
      } catch (err) {
        const { validation, message } = err.error || err || {}
        if (message) {
          enqueueSnackbar(message, {
            variant: 'error',
          })
        }
        setErrors({
          email:
            validation && validation.properties.length > 0
              ? validation.properties.map(
                (x: PropertyValidationError, index: number) =>
                  `${x.errorMessage}${index >= 1 ? ' ' : ''}`
              )
              : message || `An error occurred. Please try again.`,
        })
        setSubmitting(false)
      }
    },
  })

  useEffect(() => {
    if (errors.password) setErrors({ password: '' })
    // eslint-disable-next-line
  }, [values.password])

  const signInLink = (
    <>
      <Typography display="inline" align="right">
        Already a member?{' '}
      </Typography>
      <Typography
        display="inline"
        align="right"
        color="primary"
        onClick={handleGoToLogin}
        className={classes.link}
      >
        Sign in
      </Typography>
    </>
  )

  return (
    <Container>
      {!showAppBar && <span className={classes.absoluteLinkContainer}>{signInLink}</span>}
      {showAppBar ? (
        <AppBar>
          <span>{signInLink}</span>
        </AppBar>
      ) : (
        <BrandingSidebar />
      )}
      <div
        style={{
          display: 'flex',
          width: showAppBar ? '100%' : '70%',
        }}
      >
        <ContentCard containerHeight={970}>
          <Title title="Sign up" />

          <Form
            loginProviders={loginProviders}
            setFieldValue={setFieldValue}
            errors={errors}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            values={values}
          />
        </ContentCard>
      </div>
    </Container>
  )
}

export default CreateAccount
