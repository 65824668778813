import { useState, memo, useEffect } from 'react'
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import useMediaQuery from '@mui/material/useMediaQuery'
import Sidebar from './components/Sidebar'
import Topbar from './components/Topbar'
import PaymentAlert from './components/PaymentAlert'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: 40,
    maxWidth: 2400,
    backgroundColor: theme.palette.background.default,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  innerContainer: {
    maxWidth: '100%',
    margin: 'auto',
  },
}))

interface Props {
  children: JSX.Element | JSX.Element[]
  orgName: string
  userGrants: string[]
  roleName: string
  slimSideBar: boolean
  containerWidth?: number
  handleReCaptchaVerify: () => Promise<string | undefined>
}

const Layout = ({
  children,
  orgName,
  userGrants,
  roleName,
  slimSideBar,
  containerWidth = 1120,
  handleReCaptchaVerify,
}: Props) => {
  const classes = useStyles()
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'), {
    noSsr: true,
  })
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'), {
    noSsr: true,
  })

  const desktopMode = !slimSideBar
  const slimMode = slimSideBar

  let contentMargin = (desktopMode && 200) || (slimMode && !isMobile && 76) || 0
  if (isMobile) {
    contentMargin = 0
  }

  const [sidebarOpen, setSidebarOpen] = useState(slimSideBar)

  useEffect(() => {
    if (slimMode && sidebarOpen) setSidebarOpen(false)
  }, [isTablet, isMobile])

  return (
    <>
      <Topbar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        isMobile={Boolean(isMobile)}
        handleReCaptchaVerify={handleReCaptchaVerify}
      />
      <Sidebar
        slimMode={slimMode}
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        isMobile={Boolean(isMobile)}
        userGrants={userGrants}
        roleName={roleName}
        orgName={orgName}
        handleReCaptchaVerify={handleReCaptchaVerify}
      />
      <main
        className={classes.container}
        style={{ marginLeft: contentMargin, padding: isMobile ? 24 : 40 }}
      >
        <div className={classes.innerContainer} style={{ width: containerWidth }}>
          <PaymentAlert />
          {children}
        </div>
      </main>
    </>
  )
}

export default memo(Layout)
