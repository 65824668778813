import { memo } from 'react'
import { NavLink } from 'react-router-dom'
import authenticatedRoutes from 'routes/authenticatedRoutes'
import { hasOneOfRequiredGrants } from 'utils'

import { ListItem, ListItemIcon, ListItemText, Tooltip } from '@mui/material'

import linkStyle from '../BottomSidebarContainer/linkStyle'

interface Props {
  userGrants: string[]
  slimMode: boolean
  isMultiBrandOrg: boolean
}

const NavLinks = ({ userGrants, slimMode, isMultiBrandOrg }: Props) => {
  const classes = linkStyle()

  return (
    <>
      {authenticatedRoutes.map((route) => {
        const hasRequiredGrantForRoute =
          !route.requiresOneOf ||
          route.requiresOneOf.length === 0 ||
          hasOneOfRequiredGrants(userGrants, route.requiresOneOf)

        const blockForMultiBrand =
          route.disableForMultiBrandOrg === true && isMultiBrandOrg
        return (
          hasRequiredGrantForRoute &&
          !blockForMultiBrand &&
          route.showInSidebar && (
            <Tooltip
              title={slimMode ? route.title : ''}
              placement="right"
              arrow
              key={route.key}
            >
              <ListItem
                activeClassName={classes.active}
                className={classes.listItem}
                component={NavLink}
                to={`/${route.rootPath}`}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <route.icon className={classes.icon} />
                </ListItemIcon>
                {!slimMode && (
                  <ListItemText slot="div"
                    primary={route.title}
                    classes={{ primary: classes.listItemText }}
                  />
                )}
              </ListItem>
            </Tooltip>
          )
        )
      })}
    </>
  )
}

export default memo(NavLinks)
