// copied from https://github.com/mui/mui-x/blob/v7.18.0/packages/x-date-pickers-pro/src/SingleInputDateRangeField/SingleInputDateRangeField.tsx#L34

import * as React from 'react';
import MuiTextField from '@mui/material/TextField';
import { useThemeProps } from '@mui/material/styles';
import useSlotProps from '@mui/utils/useSlotProps';
import { useClearableField } from '@mui/x-date-pickers/hooks';
import { convertFieldResponseIntoMuiTextFieldProps } from '@mui/x-date-pickers/internals';
import { PickerValidDate } from '@mui/x-date-pickers/models';
import { PickersTextField } from '@mui/x-date-pickers/PickersTextField';
import { FieldType, SingleInputDateRangeFieldProps } from '@mui/x-date-pickers-pro';
import { useSingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField/useSingleInputDateRangeField';

type DateRangeFieldComponent = (<
  TDate extends PickerValidDate,
  TEnableAccessibleFieldDOMStructure extends boolean = false,
>(
  props: SingleInputDateRangeFieldProps<TDate, TEnableAccessibleFieldDOMStructure> &
    React.RefAttributes<HTMLDivElement>,
) => React.JSX.Element) & { propTypes?: any; fieldType?: FieldType };

/**
 * Demos:
 *
 * - [DateRangeField](http://mui.com/x/react-date-pickers/date-range-field/)
 * - [Fields](https://mui.com/x/react-date-pickers/fields/)
 *
 * API:
 *
 * - [SingleInputDateRangeField API](https://mui.com/x/api/single-input-date-range-field/)
 */
const SingleInputDateRangeField = React.forwardRef(function SingleInputDateRangeField<
  TDate extends PickerValidDate,
  TEnableAccessibleFieldDOMStructure extends boolean = false,
>(
  inProps: SingleInputDateRangeFieldProps<TDate, TEnableAccessibleFieldDOMStructure>,
  inRef: React.Ref<HTMLDivElement>,
) {
  const themeProps = useThemeProps({
    props: inProps,
    name: 'MuiSingleInputDateRangeField',
  });

  const { slots, slotProps, InputProps, inputProps, ...other } = themeProps;

  const ownerState = themeProps;

  const TextField =
    slots?.textField ??
    (inProps.enableAccessibleFieldDOMStructure ? PickersTextField : MuiTextField);
  const textFieldProps = useSlotProps({
    elementType: TextField,
    externalSlotProps: slotProps?.textField,
    externalForwardedProps: other,
    ownerState,
    additionalProps: {
      ref: inRef,
    },
  }) as SingleInputDateRangeFieldProps<TDate, TEnableAccessibleFieldDOMStructure>;

  // TODO: Remove when mui/material-ui#35088 will be merged
  textFieldProps.inputProps = { ...inputProps, ...textFieldProps.inputProps };
  textFieldProps.InputProps = { ...InputProps, ...textFieldProps.InputProps };

  const fieldResponse = useSingleInputDateRangeField<
    TDate,
    TEnableAccessibleFieldDOMStructure,
    typeof textFieldProps
  >(textFieldProps);
  const convertedFieldResponse = convertFieldResponseIntoMuiTextFieldProps(fieldResponse);

  const processedFieldProps = useClearableField({
    ...convertedFieldResponse,
    slots,
    slotProps,
  });

  const normalisedValue = inProps.value && !inProps.value[1] && inProps.format && processedFieldProps.value && typeof processedFieldProps.value === 'string' ? (processedFieldProps.value as string).split(' – ')[0] + ' - ' : processedFieldProps.value;

  return <TextField {...processedFieldProps} value={normalisedValue} />;
}) as DateRangeFieldComponent;

SingleInputDateRangeField.fieldType = 'single-input';

export { SingleInputDateRangeField };