import { Alert, DropdownMenu, MenuAvatarListItem, TicketRevListItem } from 'components';
import AppContext, { setLastVisitedBrand } from 'context';
import { useInfiniteBrands } from 'hooks';
import { useSnackbar } from 'notistack';
import React, { memo, useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { hasOneOfRequiredGrants, useDebounce, useIntersectionObserver } from 'utils';
import useSeekaInternalStaffControls from 'utils/useSeekaInternalStaffControls';
import useShopifyAppInstallContext from 'utils/useShopifyAppInstallContext';

import { Divider, InputAdornment, TextField, Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  AddRounded as AddIcon, LoyaltyRounded as BrandIcon, CheckCircle, Search as SearchIcon
} from '@mui/icons-material';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    overflow: 'hidden',
  },
  title: {
    marginLeft: theme.spacing(1.3),
  },
  titleContainer: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    display: 'flex',
    color: theme.palette.text.primary,
    marginLeft: 6,
    alignItems: 'center',
    marginBottom: theme.spacing(0.5),
  },
  paper: {
    overflow: 'visible',
    width: 284,
    [theme.breakpoints.down(600)]: {
      width: '100%',
    },
    paddingLeft: 0,
    paddingRight: 0,
  },
  paperSlim: {
    marginLeft: 9,
  },
  paperNotSlim: {
    marginLeft: -theme.spacing(),
  },
  searchContainer: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(1.5),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  brandsContainer: {
    maxHeight: 398,
    overflow: 'auto',
    paddingTop: theme.spacing(1.5),
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
  },
  createContainer: {
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
    marginTop: theme.spacing(),
  },
  inputRoot: {
    backgroundColor: theme.palette.common.white,
  },
  divider: {},
  alert: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    marginBottom: theme.spacing(1.5),
  },
}))

interface Props {
  orgName: string
  slimMode: boolean
  userGrants: string[]
}

const BrandSelect = ({ orgName, slimMode, userGrants }: Props) => {
  const canCreateBrand = hasOneOfRequiredGrants(userGrants, ['module.Org.Brands.Create'])
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const { selectedBrand, changeBrand } = useContext(AppContext)
  const classes = useStyles()
  const [searchString, setSearchString] = useState('')
  // move into component...
  const [debouncedUpdate, setDebouncedUpdate] = useState('')
  const [debouncedSearchString] = useDebounce(searchString, 350)

  const shopifyInstallContext = useShopifyAppInstallContext();
  const { hasContext } = shopifyInstallContext;

  const staffControls = useSeekaInternalStaffControls();

  useEffect(() => {
    setDebouncedUpdate(searchString)
  }, [debouncedSearchString])

  const {
    data,
    error,
    isFetchingNextPage,
    isFetching,
    fetchNextPage,
    hasNextPage,
    isLoading,
  } = useInfiniteBrands(
    {
      searchString: debouncedUpdate,
      pageSize: 50,
    },
    Boolean(anchorEl)
  )
  const flattenedBrands =
    data?.pages
      .map((x) => x.result)
      .flat() || []

  const filteredBrands = flattenedBrands.filter((x) => x.id !== selectedBrand.id) || []

  const loadMoreContainerRef = useRef<HTMLDivElement>(null)

  useIntersectionObserver({
    target: loadMoreContainerRef,
    onIntersect: fetchNextPage,
    enabled: Boolean(hasNextPage),
  })

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setSearchString('')
    setAnchorEl(null)
  }

  const goToPage = (page: string) => {
    handleClose()
    setTimeout(() => history.push(`/${page}`))
  }

  const handleSelectBrand = (newBrandId: string, newBrandName: string) => {
    if (changeBrand) {
      changeBrand(newBrandId, newBrandName)
      setLastVisitedBrand(newBrandId)
      handleClose()
      enqueueSnackbar(`Succesfully switched to ${newBrandName}`, {
        variant: 'success',
      })
      if (hasContext) {
        // Redirect to Shopify install as it is pending
        history.push('/setup/sources/shopify');
      }
    }
  }

  const handleSearchChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => setSearchString(event.target.value)

  return (<>
    <MenuAvatarListItem
      primaryText={selectedBrand.name}
      subText={orgName}
      onClick={handleClick}
      avatarOnly={slimMode}
      variant="round"
      blurText={staffControls.controls.isDemoModeEnabled}
      isOpen={Boolean(anchorEl)}
    />
    {anchorEl && (
      <DropdownMenu
        anchorEl={anchorEl}
        handleClose={handleClose}
        paperClassName={`${classes.paper} ${slimMode ? classes.paperSlim : classes.paperNotSlim
          }`}
        variant="menu"
        anchorOrigin={
          slimMode
            ? { vertical: 'top', horizontal: 'right' }
            : { vertical: 'bottom', horizontal: 'center' }
        }
        transformOrigin={
          slimMode
            ? { vertical: 'top', horizontal: 'left' }
            : { vertical: 'top', horizontal: 'center' }
        }
      >
        <div className={classes.container}>
          <div className={classes.titleContainer}>
            <Typography variant="h4" className={classes.title}>
              <strong>Select brand</strong>
            </Typography>
          </div>
          <div className={classes.searchContainer}>
            <TextField
              onChange={handleSearchChange}
              fullWidth
              variant="outlined"
              size="small"
              value={searchString}
              autoFocus
              slotProps={{
                input: {
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  classes: {
                    root: classes.inputRoot,
                  },
                }
              }}
            />
          </div>
          <Divider className={classes.divider} />
          <div className={classes.brandsContainer}>
            {isFetching && filteredBrands.length === 0 && (
              <>
                <TicketRevListItem size="small" listItemSize="smallListItem" skeleton />
                <TicketRevListItem size="small" listItemSize="smallListItem" skeleton />
                <TicketRevListItem size="small" listItemSize="smallListItem" skeleton />
                <div style={{ marginBottom: 16 }} />
              </>
            )}
            {error && (
              <Alert
                severity="error"
                message={error?.error?.message || 'An error occurred'}
                className={classes.alert}
              />
            )}
            {!isLoading && !searchString && flattenedBrands.length === 0 && (
              <Alert
                severity="info"
                message="You don't have any other brands available"
                className={classes.alert}
              />
            )}
            {searchString && !isLoading && filteredBrands.length === 0 && (
              <Alert severity="info" message="No results" className={classes.alert} />
            )}
            {flattenedBrands.map((x, index) => {
              return (
                <TicketRevListItem
                  key={x.id}
                  primaryText={x.description}
                  // secondaryText={orgName}
                  icon={selectedBrand.id === x.id ? <CheckCircle color="success" /> : <BrandIcon />}
                  onClick={
                    handleSelectBrand
                      ? () => handleSelectBrand(x.id, x.description)
                      : undefined
                  }
                  size="small"
                  listItemSize="smallListItem"
                  noWrap
                  blurPrimaryText={staffControls.controls.isDemoModeEnabled}
                  disabled={selectedBrand.id === x.id}
                  marginBottom={index === flattenedBrands.length - 1 ? 12 : 8}
                />
              )
            })}
            {isFetchingNextPage && (
              <TicketRevListItem size="small" listItemSize="smallListItem" skeleton />
            )}
            <div ref={loadMoreContainerRef} style={{ width: '100%', height: 1 }} />
          </div>
          <Divider className={classes.divider} />
          <div className={classes.createContainer}>
            <TicketRevListItem
              primaryText="Create brand"
              icon={<AddIcon />}
              onClick={() =>
                goToPage(
                  canCreateBrand
                    ? 'create-organisation?organisationModifier=hide'
                    : 'settings/brand/brand-settings'
                )
              }
              size="small"
              listItemSize="smallListItem"
            />
          </div>
        </div>
      </DropdownMenu>
    )}
  </>);
}

export default memo(BrandSelect)
