import React from 'react'
import classNames from 'classnames'
import { Theme, Tooltip } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    height: theme.spacing(1.75),
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
  },
  progressBar: {
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    transition: theme.transitions.create(['opacity', 'font-size'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shortest,
    }),
    fontSize: 0,
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.8,
      fontSize: 11,
    },
  },
  percentage: {
    marginTop: -1,
    display: 'block',
  },
}))

interface Data {
  percentage: number
  tooltip?: string
  backgroundColor: string
  textColor: string
}

interface Props {
  data: Data[]
  className?: string
}

const StackedProgressBar = ({ data, className }: Props) => {
  const classes = useStyles()

  const rootClassName = classNames(
    {
      [classes.root]: true,
    },
    className
  )

  return (
    (<div className={rootClassName}>
      {data
        .sort((a, b) => b.percentage - a.percentage)
        .map((x) => {
          return (
            // eslint-disable-next-line
            (<Tooltip title={x.percentage <= 4 ? `${x.percentage}%${x.tooltip && ` - ${x.tooltip}` || ''}` : x.tooltip || ''} arrow key={`${x.backgroundColor}${x.percentage}`} enterDelay={100}>
              <div
                role="progressbar"
                className={classes.progressBar}
                style={{
                  width: `${x.percentage}%`,
                  color: x.textColor,
                  backgroundColor: x.backgroundColor,
                }}
                aria-valuenow={x.percentage}
                aria-valuemin={0}
                aria-valuemax={100}
              >
                {x.percentage > 4 && (
                  <span className={classes.percentage}>{x.percentage}%</span>
                )}
              </div>
            </Tooltip>)
          );
        })}
    </div>)
  );
}

export default StackedProgressBar
