import React from 'react'
import classNames from 'classnames'
import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingBottom: theme.spacing(),
    paddingTop: theme.spacing(),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.border}`,
    borderBottomLeftRadius: '2px',
    borderBottomRightRadius: '2px',
  },
  noPadding: {
    padding: 0,
  },
  noDivider: {
    borderTop: 'none',
  },
  alignRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  alignSpaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}))

interface Props {
  className?: string
  noDivider?: boolean
  alignRight?: boolean
  noPadding?: boolean
  alignSpaceBetween?: boolean
  children: React.ReactNode
}

const PaperFooter = ({
  className,
  noDivider,
  alignRight,
  noPadding,
  alignSpaceBetween,
  children,
}: Props) => {
  const classes = useStyles()

  const rootClassName = classNames(
    {
      [classes.root]: true,
      [classes.noDivider]: noDivider,
      [classes.alignRight]: alignRight,
      [classes.noPadding]: noPadding,
      [classes.alignSpaceBetween]: alignSpaceBetween,
    },
    className
  )

  return <div className={rootClassName}>{children}</div>
}

export default PaperFooter
