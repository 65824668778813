import { Stack, Theme, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import VerificationInput from 'react-verification-input';

const CODE_LENGTH = 4

const useStyles = makeStyles((theme: Theme) => ({
  buttonWrapper: {
    width: '100%',
  },
  loginButton: {
    height: theme.spacing(5),
    width: '100%',
  },
  numberInput: {
    marginBottom: theme.spacing(3),
  },
  characterInput: {
    fontFamily: 'monospace',
          borderRadius: theme.shape.borderRadius,
          border: '1px solid',
          borderColor: theme.palette.divider,
          padding: 0,
          width: `${100 / CODE_LENGTH - 5}%`,
          height: '65px',
          fontSize: '32px',
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.common.white,
          textAlign: 'center',
          outline: 0,
          margin: `2.5%`,
          lineHeight: '58px'
  },
}))

interface Props {
  emailVerificationCode: string
  setValue: (val: string) => void
  disabled: boolean
}

const VerificationCodeInput = ({ emailVerificationCode, setValue, disabled }: Props) => {
  const classes = useStyles()

  return (
    <Stack justifyContent={'center'} alignItems={'center'} mb={2}>
      <VerificationInput
      // type="tel"
      length={CODE_LENGTH}
      validChars="0-9"
      inputProps={{
        name: "emailVerificationCode",
      }}      
      // inputMode="numeric"
      value={emailVerificationCode}
      placeholder=''
      onChange={(value: string) => setValue(value)}
      autoFocus={true}
      classNames={{
        container: classes.numberInput,
        character: classes.characterInput
      }}
    />
    </Stack>
  )
}

export default VerificationCodeInput
