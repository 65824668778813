import React from 'react'
import classNames from 'classnames'
import { CircularProgress } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  progressWrapper: {
    padding: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
  },
  primary: {
    color: theme.palette.primary.main,
  },
  white: {
    color: theme.palette.common.white,
  },
}))

interface Props {
  className?: string
  color?: 'primary' | 'white'
  size?: number
  compact?: boolean
  style?: React.CSSProperties
}

const PaperLoader = ({
  className,
  compact,
  color = 'primary',
  size = 40,
  ...rest
}: Props) => {
  const classes = useStyles()

  const rootClassName = classNames(
    {
      [classes.progressWrapper]: !compact,
      [classes.primary]: color === 'primary',
      [classes.white]: color === 'white',
    },
    className
  )

  return (
    <div {...rest} className={rootClassName}>
      <CircularProgress color="inherit" size={size} />
    </div>
  )
}

export default PaperLoader
