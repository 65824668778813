import {
  AnalyticsLibrary, ConvergeVendorDestinationPublishStrategy
} from '@seeka-labs-internal/lib-api-app';
import { sentenceCase, sentenceCaseWithLowercase } from 'views/Settings/Diagnostics/LogStream';

export const platformNameMapping = {
  [AnalyticsLibrary.SnapchatPixel]: 'Snapchat™',
  [AnalyticsLibrary.FacebookPixel]: 'Meta™',
  [AnalyticsLibrary.Braze]: 'Braze',
  [AnalyticsLibrary.DynamicYield]: 'Dynamic Yield',
  [AnalyticsLibrary.GoogleAnalytics]: 'Google™ Analytics',
  [AnalyticsLibrary.Pinterest]: 'Pinterest™',
  [AnalyticsLibrary.Seeka]: 'Seeka',
  [AnalyticsLibrary.Segment]: 'Segment™',
  [AnalyticsLibrary.TikTokPixel]: 'TikTok™',
  googleAds: 'Google™ Ads',
}

export const eventNameMapping = {
  AddPaymentInfo: 'Add payment info',
  AddToCart: 'Add to cart',
  AddToWishlist: 'Add to wishlist',
  CompleteRegistration: 'Complete registration',
  Contact: 'Contact',
  CustomizeProduct: 'Customize product',
  Donate: 'Donate',
  FindLocation: 'Find location',
  InitiateCheckout: 'Initiate checkout',
  Lead: 'Lead',
  PageView: 'Page view',
  Purchase: 'Purchase',
  Schedule: 'Schedule',
  Search: 'Search',
  StartTrial: 'Start trial',
  SubmitApplication: 'Submit application',
  Subscribe: 'Subscribe',
  ViewContent: 'View content',
  KeywordSearch: 'Keyword search',
  AddPaymentMethod: 'Add payment method',
  ViewProduct: 'View product',
  ViewPage: 'View page',
  Order: 'Order',
  ViewContentItem: 'View content item',
}

export const getFriendlyEventName = (eventName: string, maxLength?: number) => {
  let mappedEventName =
    eventName in eventNameMapping
      ? eventNameMapping[eventName]
      : sentenceCaseWithLowercase(eventName)

  if (maxLength && maxLength > 0) {
    const shouldTruncateName = mappedEventName.length >= maxLength
    mappedEventName = shouldTruncateName
      ? `${mappedEventName.substring(0, maxLength)}...`
      : mappedEventName
  }

  return mappedEventName;
}

export const getStrategyMetadata = (
  strategy?: ConvergeVendorDestinationPublishStrategy
) => {
  if (!strategy)
    return {
      title: null,
      subtitle: null,
    }
  const strategyNumber =
    Object.values(ConvergeVendorDestinationPublishStrategy)
      .map((x) => x)
      .indexOf(strategy) + 1
  if (strategyNumber === 1) {
    return {
      title: 'Strategy 1',
      subtitle: 'Send all available data (Recommended)',
    }
  }
  if (strategyNumber === 2) {
    return {
      title: 'Strategy 2',
      subtitle: 'Send the maximum amount of data in the most hygienic way.',
    }
  }
  if (strategyNumber === 3) {
    return {
      title: 'Strategy 3',
      subtitle: 'Send the maximum amount of data in with a medium level of hygiene.',
    }
  }
  if (strategyNumber === 4) {
    return {
      title: 'Strategy 4',
      subtitle: 'Send the minimum amount of data in the most hygienic way.',
    }
  }
  return {
    title: undefined,
    subtitle: 'Could not identify current strategy',
    description: '',
  }
}
