import { DropdownMenu, MenuAvatarListItem } from 'components'
import AppContext, { setLastVisitedBrand } from 'context'
import { useSnackbar } from 'notistack'
import React, { memo, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import ViewMenu from './components/ViewMenu'
import ViewSwitchBrand from './components/ViewSwitchBrand'
import ViewSwitchOrganisation from './components/ViewSwitchOrganization'
import { getShowMenuDimensions } from './helpers'

const useStyles = makeStyles<Theme, { paperHeight: number; paperWidth: number }>(
  (theme: Theme) => ({
    container: ({ paperHeight, paperWidth }) => {
      return {
        width: paperWidth,
        height: paperHeight,
        transition: theme.transitions.create(['width', 'height'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        overflow: 'hidden',
      }
    },
    paper: {
      marginLeft: -theme.spacing(),
      overflow: 'visible',
    },
  })
)

interface Props {
  orgName: string
  roleName: string
  userGrants: string[]
  slimMode: boolean
  handleReCaptchaVerify: () => Promise<string | undefined>
}

const OrganisationSelect = ({
  orgName,
  roleName,
  userGrants,
  slimMode,
  handleReCaptchaVerify,
}: Props) => {
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [toggleAnimations, setToggleAnimations] = useState(false)
  const { selectedBrand, changeBrand } = useContext(AppContext)
  const hasBrands = true // userGrants.includes('seeka.Brands')
  const [dimensions, setDimensions] = useState<{
    paperHeight: number
    paperWidth: number
  }>(getShowMenuDimensions(userGrants.includes('settingManagement.Enable'), hasBrands))
  const classes = useStyles(dimensions)
  const [view, setView] = useState<'switch-brand' | 'switch-organisation' | 'show-menu'>(
    'show-menu'
  )

  useEffect(() => {
    if (view === 'show-menu') {
      setDimensions(
        getShowMenuDimensions(userGrants.includes('settingManagement.Enable'), hasBrands)
      )
    }
  }, [view])

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleToggleView = (newView: typeof view) => {
    if (!toggleAnimations) setToggleAnimations(true)
    setView(newView)
  }

  const handleClose = () => {
    setView('show-menu')
    setToggleAnimations(false)
    setAnchorEl(null)
  }

  const goToPage = (page: string) => {
    handleClose()
    setTimeout(() => history.push(`/${page}`))
  }

  const handleSetDimensions = (newDimensions: typeof dimensions) => {
    setDimensions(newDimensions)
  }

  const handleSelectBrand = (newBrandId: string, newBrandName: string) => {
    if (changeBrand) {
      enqueueSnackbar('Brand changed!', {
        variant: 'info',
      })
      changeBrand(newBrandId, newBrandName)
      setLastVisitedBrand(newBrandId)
      handleClose()
    }
  }

  return (
    <>
      <MenuAvatarListItem
        primaryText={hasBrands ? selectedBrand.name : orgName}
        subText={hasBrands ? orgName : roleName}
        onClick={handleClick}
        avatarOnly={slimMode}
      />
      {anchorEl && (
        <DropdownMenu
          anchorEl={anchorEl}
          handleClose={handleClose}
          paperClassName={classes.paper}
        >
          <div className={classes.container}>
            {view === 'show-menu' && (
              <ViewMenu
                orgName={orgName}
                hasBrands={hasBrands}
                brandName={selectedBrand.name}
                userGrants={userGrants}
                roleName={roleName}
                toggleView={handleToggleView}
                goToPage={goToPage}
                withAnimation={toggleAnimations}
              />
            )}
            {view === 'switch-brand' && (
              <ViewSwitchBrand
                toggleView={handleToggleView}
                goToPage={goToPage}
                brandId={selectedBrand.id}
                handleSelectBrand={handleSelectBrand}
                handleSetDimensions={handleSetDimensions}
              />
            )}
            {view === 'switch-organisation' && (
              <ViewSwitchOrganisation
                toggleView={handleToggleView}
                orgId={orgName}
                handleSetDimensions={handleSetDimensions}
                handleReCaptchaVerify={handleReCaptchaVerify}
              />
            )}
          </div>
        </DropdownMenu>
      )}
    </>
  )
}

export default memo(OrganisationSelect)
