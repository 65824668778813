import config from 'config'
import { createRoot } from 'react-dom/client'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { BrowserRouter } from 'react-router-dom'
import { queryClient } from 'services'

import { CssBaseline, StyledEngineProvider, Theme, ThemeProvider } from '@mui/material'

import App from './App'
import theme from './theme'

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme { }
}

const TicketRevApp = () => {
  return (
    // <StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <GoogleReCaptchaProvider reCaptchaKey={config.recaptchaV3SiteKey}>
              <App />
            </GoogleReCaptchaProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </QueryClientProvider>
    </BrowserRouter>
    // </StrictMode>
  );
}

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(<TicketRevApp />);