import { useEffect, memo } from 'react'
import { Switch, Redirect } from 'react-router-dom'
import { UserProfile, Organisation } from '@seeka-labs-internal/lib-api-app'
import authenticatedRoutes from './authenticatedRoutes'
import AuthenticatedRoute from './AuthenticatedRoute'
// Hide Google Recaptcha widget thingy only if user is logged in
import '../app.css'

interface Props {
  organisation: Organisation
  roles: string[]
  roleName: string
  handleReCaptchaVerify: () => Promise<string | undefined>
}

const AuthenticatedMe = ({
  organisation,
  roles,
  roleName,
  handleReCaptchaVerify,
}: Props) => {

  return (
    <Switch>
      {authenticatedRoutes.map((route) => {
        return (
          <AuthenticatedRoute
            key={route.key}
            path={route.matchPath}
            exact
            requiresOneOf={route.requiresOneOf}
            hasLayout={route.hasLayout}
            organisation={organisation}
            roles={roles}
            component={route.component}
            roleName={roleName}
            containerWidth={route.containerWidth}
            slimSideBar={route.slimSideBar}
            handleReCaptchaVerify={handleReCaptchaVerify}
          />
        )
      })}
      <Redirect to="/dashboard/signals" />
    </Switch>
  )
}

export default memo(AuthenticatedMe)
