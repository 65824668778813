import {
  CreateOrUpdateOrganisationBrandRequest,
  OrganisationProfileUpdateRequest,
  OrganisationRegistrationRequest,
} from '@seeka-labs-internal/lib-api-app'
import AppContext, { setLastVisitedBrand } from 'context'
import { useFormik } from 'formik'
import meQueryKeys from 'hooks/me/queryKeys'
import orgQueryKeys from 'hooks/org/queryKeys'
import { useSnackbar } from 'notistack'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useQueryClient } from 'react-query'
import { RouteComponentProps, useLocation } from 'react-router-dom'
import { api, queryKeys } from 'services'
import loginManager from 'services/loginManager'
import {
  getToken,
  removeToken,
  setToken,
  transformApiErrors,
  useDocumentTitle,
} from 'utils'

import { useSeekaConverge } from '@seeka-labs/converge-react'

import { setCookieItem } from 'utils/cookie'
import { getPartnerCode } from 'utils/partners'
import Buttons from './components/Buttons'
import NameYourBrand from './components/NameYourBrand'
import NameYourOrganisation from './components/NameYourOrganisation'
import Topbar from './components/Topbar'

const steps = ['Name your organisation', 'Name your brand']

const CreateOrganisation = ({ history }: RouteComponentProps) => {
  const { changeBrand } = useContext(AppContext)
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const location = useLocation()

  const [step, setStep] = useState(0)
  const [hasVisitedBrandStep, setHasVisitedBrandStep] = useState(false)

  const queryParams = new URLSearchParams(location.search)
  const organisationHidden = queryParams.get('organisationModifier') === 'hide'
  const existingOrganisation = queryParams.get('organisationModifier') === 'update'

  const { executeRecaptcha } = useGoogleReCaptcha()
  const handleReCaptchaVerify = useCallback(async () => {
    if (executeRecaptcha) {
      const token = await executeRecaptcha(
        location.pathname.replace(/\//g, '').replace(/-/g, '')
      )
      return token
    }
    return undefined
  }, [executeRecaptcha])

  useDocumentTitle(`Create organisation - ${steps[step]}`)

  useEffect(() => {
    if (step === 1 && !hasVisitedBrandStep) setHasVisitedBrandStep(true)
  }, [step])

  useEffect(() => {
    if (organisationHidden) setStep(1)
  }, [organisationHidden])

  const handleNextStep = () => setStep(step < steps.length ? step + 1 : step)
  const handlePreviousStep = () => setStep(step > 0 ? step - 1 : 0)

  const seekaConverge = useSeekaConverge()

  const {
    setFieldValue,
    handleSubmit,
    values,
    setSubmitting,
    isSubmitting,
    errors,
    setErrors,
  } = useFormik({
    initialValues: {
      orgName: '',
      brandName: '',
      billingAccountEmailAddress: '',
      legalEntityTradingName: '',
    },
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (payload) => {
      setSubmitting(true)
      const orgBody = OrganisationRegistrationRequest.fromJS({
        organisationDescription: payload.orgName,
      })

      try {
        if (existingOrganisation) {
          const body = OrganisationProfileUpdateRequest.fromJS({
            description: payload.orgName,
          })
          await api.org.base.update(body)
          queryClient.invalidateQueries(meQueryKeys.me)
          queryClient.invalidateQueries(orgQueryKeys.organisations)
        } else if (!organisationHidden) {
          // if user is not logged in
          const orgResponse = await api.account.registerNewTenant(orgBody)

          const token = await handleReCaptchaVerify()
          const newToken = await loginManager.getTokenForOrgOrThrow(
            getToken() as string,
            orgResponse.result.id,
            token as string
          )
          setToken(newToken.accessToken)
        }

        // Create brand in org
        const partnerCode = getPartnerCode(location.search)
        const brandBody = CreateOrUpdateOrganisationBrandRequest.fromJS({
          description: payload.brandName,
          billingAccountEmailAddress: payload.billingAccountEmailAddress,
          legalEntityTradingName: payload.legalEntityTradingName,
          referringPartnerKey: partnerCode,
        })
        const { result } = await api.org.brand.createOrganisationBrand(brandBody)
        enqueueSnackbar(`Brand created`, { variant: 'success' })
        if (partnerCode) {
          setCookieItem('seeka_partner_key', '', 1)
        }

        if (changeBrand) {
          changeBrand(result.id, result.description)
          setLastVisitedBrand(result.id)
        }
        await seekaConverge?.track.startTrial({
          sourceContentName: 'Brand created',
        })
        queryClient.invalidateQueries(queryKeys.brands)
        queryClient.clear()
        history.push(`/plans`)
      } catch (response) {
        const { message, validation } = response.error || response || {}
        enqueueSnackbar(`${message || 'An error occurred'}`, { variant: 'error' })
        if (validation && validation.properties) {
          setErrors(transformApiErrors(validation.properties))
        }
      } finally {
        setSubmitting(false)
      }
    },
  })

  const setFieldValueWrapper = (name: string, value: any) => {
    setFieldValue(name, value)
    if (name in errors) setErrors({ ...errors, [name]: undefined })
  }

  const sharedProps = {
    handleNextStep,
    handlePreviousStep,
    setValue: setFieldValueWrapper,
    errors,
  }

  const handleExit = () => history.push('/settings')

  let stepContent = (
    <>
      <NameYourOrganisation {...sharedProps} orgName={values.orgName} />
      <Buttons
        organisationHidden={false}
        handleNext={handleNextStep}
        nextDisabled={values.orgName.length < 3}
      />
    </>
  )

  if (step === 1) {
    stepContent = (
      <>
        <NameYourBrand
          {...sharedProps}
          brandName={values.brandName}
          billingAccountEmailAddress={values.billingAccountEmailAddress}
          legalEntityTradingName={values.legalEntityTradingName}
          isSubmitting={isSubmitting}
        />
        <Buttons
          handlePrevious={handlePreviousStep}
          handleNext={handleSubmit}
          isSubmit
          nextDisabled={
            values.brandName.length < 3 ||
            values.billingAccountEmailAddress.length < 3 ||
            values.legalEntityTradingName.length < 3
          }
          backDisabled={isSubmitting}
          isSubmitting={isSubmitting}
          organisationHidden={organisationHidden}
        />
      </>
    )
  }

  return (
    <>
      <Topbar
        handleExit={
          existingOrganisation
            ? () => {
              removeToken()
              queryClient.clear()
              history.push(`/sign-in`)
            }
            : handleExit
        }
        title={organisationHidden ? 'Create a brand' : 'Create a organisation'}
      />
      <div
        style={{
          maxWidth: 1060,
          margin: 'auto',
          paddingTop: 100,
          paddingLeft: 32,
          paddingRight: 32,
          paddingBottom: 110,
        }}
      >
        {stepContent}
      </div>
    </>
  )
}

export default CreateOrganisation
