import {
  AnalyticsLibrary,
  ConvergeActivityLogListRequest,
  ConvergeActivityLogRecord,
  ConvergePipelineLoggableActivityType,
  ConvergeSdkLogEventLevel,
} from '@seeka-labs-internal/lib-api-app'
import { ConvergeIcon } from 'components'
import { PrivacyAlert } from 'components/Privacy/Alert'
import useTabActive from 'hooks/browser'
import { camelCase, isArray, startCase, truncate } from 'lodash-es'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { api } from 'services'
import Topbar from 'views/Settings/CreateOrganisation/components/Topbar'

import {
  Block,
  BugReport,
  Clear,
  DeveloperModeOutlined,
  ErrorOutlined,
  Face,
  FaceOutlined,
  FiberManualRecord,
  InfoOutlined,
  KeyboardArrowDown,
  KeyboardArrowUp,
  KeyboardTabOutlined,
  SendOutlined,
  SettingsApplicationsOutlined,
  WarningOutlined,
} from '@mui/icons-material'
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Collapse,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  TextFieldProps,
  Tooltip,
  Typography,
} from '@mui/material'
import Alert from '@mui/material/Alert'
import Grid, { GridProps } from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import { Theme, useTheme } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import makeStyles from '@mui/styles/makeStyles'
import { defaultRowsPerPage } from 'components/Table/Table'

const useStyles = makeStyles({
  table: {
    width: '100%',
  },
  root: {
    width: '100%',
  },
})

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
})

function isJsonString(str) {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

export function sentenceCase(str: string) {
  return startCase(camelCase(str))
}

export function sentenceCaseWithLowercase(str: string) {
  const a = startCase(camelCase(str))

  return a[0].toUpperCase() + a.slice(1).toLowerCase()
}

function cleanData(obj) {
  if (obj === null || obj === undefined) return null

  if (isArray(obj)) return (obj as []).filter((i) => i !== null && i !== undefined)

  return Object.fromEntries(
    Object.entries(obj)
      .filter(([_, v]) => v !== null && v !== undefined)
      .map(([k, v]) => [k, v === Object(v) ? cleanData(v) : v])
  )
}

const getLogLevelColumn = (logLevel: ConvergeSdkLogEventLevel, theme: Theme) => {
  switch (logLevel) {
    case ConvergeSdkLogEventLevel.Warning:
      return (
        <Tooltip title={sentenceCase(logLevel.toString())}>
          <WarningOutlined
            fontSize="small"
            style={{ color: theme.palette.warning.main }}
          />
        </Tooltip>
      )
    case ConvergeSdkLogEventLevel.Error:
      return (
        <Tooltip title={sentenceCase(logLevel.toString())}>
          <ErrorOutlined fontSize="small" style={{ color: theme.palette.error.main }} />
        </Tooltip>
      )
    case ConvergeSdkLogEventLevel.Verbose:
      return (
        <Tooltip title={sentenceCase(logLevel.toString())}>
          <SettingsApplicationsOutlined
            fontSize="small"
            style={{ color: theme.palette.grey[500] }}
          />
        </Tooltip>
      )
    default:
      return (
        <Tooltip title={sentenceCase(logLevel.toString())}>
          <InfoOutlined fontSize="small" style={{ color: theme.palette.info.main }} />
        </Tooltip>
      )
  }
}

const getDataQualityColumn = (row: ConvergeActivityLogRecord, theme: Theme) => {
  if (
    !row.identifiersMetrics ||
    row.identifiersMetrics.qualityRating === null ||
    row.identifiersMetrics.qualityRating === undefined
  ) {
    return null
  }

  const color =
    row.identifiersMetrics.qualityRating >= 7
      ? theme.palette.success.main
      : theme.palette.grey[500]

  return (
    <Tooltip
      title={`Data quality of ${row.identifiersMetrics.qualityRating} with ${row.identifiersMetrics.dataPoints} signals`}
    >
      <Chip
        variant="outlined"
        label={row.identifiersMetrics.qualityRating}
        style={{ color, borderColor: color }}
        size="small"
        icon={<Face style={{ color, borderColor: color }} />}
      />
    </Tooltip>
  )
}

const getPlatformForRow = (row: ConvergeActivityLogRecord) => {
  if (!row.messageTemplateParameters) return 'other'

  if (row.messageTemplateParameters.facebook_EventName) return 'facebook'
  if (row.messageTemplateParameters.snapchat_EventName) return 'snapchat'
  if (row.messageTemplateParameters.tikTok_EventName) return 'tiktok'
  if (row.messageTemplateParameters.pinterest_EventName) return 'pinterest'
  if (row.messageTemplateParameters.googleAnalyticsStream_EventName)
    return 'google-analytics'

  return 'other'
}

const getLogMessageColumn = (
  row: ConvergeActivityLogRecord,
  setFilterActivityName: (i: string) => void,
  setFilterActivityId: (i: string) => void
) => {
  if (
    row.logType === ConvergePipelineLoggableActivityType.ActivityEgress &&
    row.messageTemplateParameters
  ) {
    let platformName
    let platformEventName
    let platformDestinationIdentifier
    const activityId = row.messageTemplateParameters.activity_ActivityIdentifier

    const platform = getPlatformForRow(row)

    if (platform === 'facebook') {
      // Meta
      platformName = 'Meta server'
      platformEventName = row.messageTemplateParameters.facebook_EventName
      platformDestinationIdentifier = (
        <Button
          variant="text"
          size="small"
          color="primary"
          target="_blank"
          style={{ minWidth: '0' }}
          href={`https://business.facebook.com/events_manager2/list/dataset/${row.messageTemplateParameters.facebookPixel_FacebookIdentifier}`}
        >
          {row.messageTemplateParameters.facebookPixel_FacebookIdentifier}
        </Button>
      )
    } else if (platform === 'snapchat') {
      // Snapchat
      platformName = 'Snapchat server'
      platformEventName = row.messageTemplateParameters.snapchat_EventName
      platformDestinationIdentifier = (
        <Button
          variant="text"
          size="small"
          color="primary"
          target="_blank"
          style={{ minWidth: '0' }}
          href="https://ads.snapchat.com"
        >
          {row.messageTemplateParameters.snapchatPixel_SnapchatIdentifier}
        </Button>
      )
    } else if (platform === 'tiktok') {
      // TikTok
      platformName = 'TikTok server'
      platformEventName = row.messageTemplateParameters.tikTok_EventName
      platformDestinationIdentifier = (
        <Button
          variant="text"
          size="small"
          color="primary"
          target="_blank"
          style={{ minWidth: '0' }}
          href={`https://ads.tiktok.com/i18n/events_manager/pixel/detail/${row.messageTemplateParameters.tikTokPixel_TikTokIdentifier}`}
        >
          {row.messageTemplateParameters.tikTokPixel_TikTokIdentifier}
        </Button>
      )
    } else if (platform === 'pinterest') {
      // Pinterest
      platformName = 'Pinterest server'
      platformEventName = row.messageTemplateParameters.pinterest_EventName
      platformDestinationIdentifier = (
        <Button
          variant="text"
          size="small"
          color="primary"
          target="_blank"
          style={{ minWidth: '0' }}
          href={`https://ads.pinterest.com/advertiser/${row.messageTemplateParameters.pinterestPixel_PinterestAdAccountId}/conversions/event-history/`}
        >
          {row.messageTemplateParameters.pinterestPixel_PinterestTagIdentifier}
        </Button>
      )
    } else if (platform === 'google-analytics') {
      // GA4
      platformName = 'GA4 server'
      platformEventName = row.messageTemplateParameters.googleAnalyticsStream_EventName
      platformDestinationIdentifier = (
        <Button
          variant="text"
          size="small"
          color="primary"
          target="_blank"
          style={{ minWidth: '0' }}
          href="https://analytics.google.com/analytics/web/"
        >
          {row.messageTemplateParameters.googleAnalyticsStream_MeasurementId}
        </Button>
      )
    }

    return (
      <Typography variant="body2">
        Sent{' '}
        <Button
          variant="text"
          size="small"
          color="primary"
          style={{ minWidth: '0' }}
          onClick={() =>
            setFilterActivityName(row.messageTemplateParameters.activity_Name)
          }
        >
          {platformEventName}
        </Button>{' '}
        with ID{' '}
        <Button
          variant="text"
          size="small"
          color="primary"
          style={{ minWidth: '0' }}
          onClick={() => setFilterActivityId(activityId)}
        >
          {activityId}
        </Button>
        to {platformName} {platformDestinationIdentifier}
      </Typography>
    )
  }

  if (
    row.logType === ConvergePipelineLoggableActivityType.ActivityIngress &&
    row.messageTemplateParameters
  ) {
    const activityId = row.messageTemplateParameters.activity_ActivityIdentifier
    const activityName = row.messageTemplateParameters.activity_Name
    // const source = row.messageTemplateParameters.converge_ActivityLog_Source_Method

    return (
      <Typography variant="body2">
        Accepted{' '}
        <Button
          variant="text"
          size="small"
          color="primary"
          style={{ minWidth: '0' }}
          onClick={() => setFilterActivityName(activityName)}
        >
          {activityName}
        </Button>{' '}
        with ID{' '}
        <Button
          variant="text"
          size="small"
          color="primary"
          style={{ minWidth: '0' }}
          onClick={() => setFilterActivityId(activityId)}
        >
          {activityId}
        </Button>
        {/* from {source} */}
      </Typography>
    )
  }

  return (
    <Typography variant="body2">
      {row.messageTemplateRendered || row.messageTemplate}
    </Typography>
  )
}

const iconSize = 25

const getLogTypeColumn = (row: ConvergeActivityLogRecord, theme: Theme) => {
  const type = row.logType
  switch (type) {
    case ConvergePipelineLoggableActivityType.ActivityEgress: {
      const platform = getPlatformForRow(row)
      let icon = (
        <SendOutlined fontSize="small" style={{ color: theme.palette.success.main }} />
      )
      let title = sentenceCase(type.toString())

      switch (platform) {
        case 'facebook':
          icon = (
            <ConvergeIcon converge={AnalyticsLibrary.FacebookPixel} size={iconSize} />
          )
          title = 'Sent to Meta'
          break
        case 'snapchat':
          icon = (
            <ConvergeIcon converge={AnalyticsLibrary.SnapchatPixel} size={iconSize} />
          )
          title = 'Sent to Snapchat'
          break
        case 'tiktok':
          icon = <ConvergeIcon converge={AnalyticsLibrary.TikTokPixel} size={iconSize} />
          title = 'Sent to TikTok'
          break
        case 'pinterest':
          icon = <ConvergeIcon converge={AnalyticsLibrary.Pinterest} size={iconSize} />
          title = 'Sent to Pinterest'
          break
        case 'google-analytics':
          icon = (
            <ConvergeIcon converge={AnalyticsLibrary.GoogleAnalytics} size={iconSize} />
          )
          title = 'Sent to Google Analytics'
          break
        default:
          break
      }

      // return <Tooltip title={title}>{icon}</Tooltip>
      return icon
    }
    case ConvergePipelineLoggableActivityType.ActivityIngress:
      return (
        <Tooltip title={sentenceCase(type.toString())}>
          <KeyboardTabOutlined
            fontSize="small"
            style={{ color: theme.palette.info.main }}
          />
        </Tooltip>
      )
    case ConvergePipelineLoggableActivityType.ActivityEgressFilter:
      return (
        <Tooltip title={sentenceCase(type.toString())}>
          <Block fontSize="small" style={{ color: theme.palette.error.main }} />
        </Tooltip>
      )
    case ConvergePipelineLoggableActivityType.UserProfile:
      return (
        <Tooltip title={sentenceCase(type.toString())}>
          <FaceOutlined fontSize="small" style={{ color: theme.palette.primary.main }} />
        </Tooltip>
      )
    case ConvergePipelineLoggableActivityType.SdkLog:
      return (
        <Tooltip title={sentenceCase(type.toString())}>
          <DeveloperModeOutlined
            fontSize="small"
            style={{ color: theme.palette.grey[500] }}
          />
        </Tooltip>
      )
    case ConvergePipelineLoggableActivityType.Diagnosis:
      return (
        <Tooltip title={sentenceCase(type.toString())}>
          <BugReport fontSize="small" style={{ color: theme.palette.grey[500] }} />
        </Tooltip>
      )
    default:
      return (
        <Tooltip title={sentenceCase(type.toString())}>
          <FiberManualRecord
            fontSize="small"
            style={{ color: theme.palette.grey[500] }}
          />
        </Tooltip>
      )
  }
}

const DataRow = (props: {
  row: ConvergeActivityLogRecord
  theme: Theme
  setFilterActivityName: (i: string) => void
  setFilterActivityId: (i: string) => void
  setFilterPersonId: (i: string) => void
  setFilterDiagnosticSessionId: (i: string) => void
  setFilterSourceUrl: (i: string) => void
  onOpenToggle: (isOpen: boolean, row: ConvergeActivityLogRecord) => void
}) => {
  const {
    row,
    theme,
    setFilterActivityName,
    setFilterActivityId,
    setFilterPersonId,
    setFilterDiagnosticSessionId,
    setFilterSourceUrl,
    onOpenToggle,
  } = props

  const classes = useRowStyles()
  const [open, setOpen] = React.useState(false)

  const onOpenChange = (isOpen: boolean) => {
    setOpen(isOpen)
    onOpenToggle(isOpen, row)
  }

  const codeDataCard = (title: string, data: any) => {
    const srcData = JSON.parse(JSON.stringify(data) ?? '{}')
    if (srcData) {
      if (srcData.payloadPublished) {
        srcData.payloadPublished = null
      }
      if (srcData.payloadUnhashed) {
        srcData.payloadUnhashed = null
      }
    }

    const cleaned = cleanData(srcData)
    if (!cleaned || Object.keys(cleaned).length === 0) return null

    return (
      // <Grid item xs={12} md={6} lg={6} xl={4}>
      (<Grid item xs={12}>
        <Card variant="outlined" style={{ width: '100%', overflow: 'hidden' }}>
          <CardContent>
            <Typography variant="h5" gutterBottom>
              <strong>{title}</strong>
            </Typography>
            <code>
              <pre>{JSON.stringify(cleaned, null, 2)}</pre>
            </code>
          </CardContent>
        </Card>
      </Grid>)
    );
  }

  return (
    <>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => onOpenChange(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell>{getLogLevelColumn(row.logLevel, theme)}</TableCell>
        <TableCell>{getLogTypeColumn(row, theme)}</TableCell>
        <TableCell>{getDataQualityColumn(row, theme)}</TableCell>
        <Tooltip title={row.raisedAt.format('LLL')}>
          <TableCell component="th" scope="row">
            <Typography variant="body2">{row.raisedAt.fromNow()}</Typography>
          </TableCell>
        </Tooltip>

        <TableCell>
          {getLogMessageColumn(row, setFilterActivityName, setFilterActivityId)}
        </TableCell>
      </TableRow>
      <TableRow
        style={{
          height: 'auto',
          background: 'transparent',
          borderLeft: `3px solid ${theme.palette.border}`,
        }}
      >
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            background: 'transparent',
          }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Grid
              container
              spacing={2}
              style={{
                paddingTop: theme.spacing(2),
                paddingBottom: theme.spacing(2),
                marginBottom: theme.spacing(2),
                width: '100%',
              }}
            >
              <Grid item sm={12}>
                {row.activity?.source?.loc && (
                  <>
                    Source URL:
                    <Button
                      variant="text"
                      size="small"
                      color="primary"
                      style={{
                        maxWidth: '500px',
                        minWidth: '0',
                      }}
                      onClick={() => setFilterSourceUrl(row.activity.source.loc)}
                    >
                      {truncate(row.activity.source.loc, { length: 150 })}
                    </Button>
                    <br />
                  </>
                )}
                Person ID:
                <Button
                  variant="text"
                  size="small"
                  color="primary"
                  style={{ minWidth: '0' }}
                  onClick={() => setFilterPersonId(row.personId)}
                >
                  {row.personId}
                </Button>
                <br />
                {row.diagnosisSessionIdentifier && (
                  <>
                    Diagnosis session ID:
                    <Button
                      variant="text"
                      size="small"
                      color="primary"
                      style={{ minWidth: '0' }}
                      onClick={() =>
                        setFilterDiagnosticSessionId(row.diagnosisSessionIdentifier)
                      }
                    >
                      {row.diagnosisSessionIdentifier}
                    </Button>
                    <br />
                  </>
                )}
                {row.activity?.source?.sess && (
                  <>
                    Identity session ID:
                    <Button
                      variant="text"
                      size="small"
                      color="primary"
                      style={{ minWidth: '0' }}
                      onClick={() =>
                        setFilterDiagnosticSessionId(row.activity.source?.sess as string)
                      }
                    >
                      {row.activity?.source?.sess}
                    </Button>
                    <br />
                  </>
                )}
              </Grid>
              <Grid
                container
                item
                spacing={2}
                xs={12}
                style={{
                  width: '100%',
                }}
              >
                {codeDataCard(
                  'Sent event',
                  row.properties.payloadPublished
                    ? JSON.parse(row.properties.payloadPublished)
                    : null
                )}
                {codeDataCard(
                  'Sent event (unhashed & redacted)',
                  row.properties.payloadUnhashed
                    ? JSON.parse(row.properties.payloadUnhashed)
                    : null
                )}
                {codeDataCard('Source event', row.activity.payload)}
                {codeDataCard('Source metadata', {
                  utm: row.activity.utm || {},
                  source: row.activity.source,
                })}
                {codeDataCard(
                  'Properties',
                  row.properties.props && isJsonString(row.properties.props)
                    ? JSON.parse(row.properties.props)
                    : row.properties
                )}
                {codeDataCard('Identity', row.identifiers)}
              </Grid>

              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <Button
                  aria-label="expand row"
                  size="small"
                  color="primary"
                  variant="outlined"
                  onClick={() => onOpenChange(!open)}
                >
                  <KeyboardArrowUp />
                  Collapse
                </Button>
              </Grid>
            </Grid>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export default function LogStreamTable() {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const theme = useTheme()
  const queryParams = new URLSearchParams(location.search)
  const isTabActive = useTabActive()

  const [filterLogLevel, setFilterLogLevel] = useState<ConvergeSdkLogEventLevel[]>([])
  const [filterLogType, setFilterLogType] = useState<
    ConvergePipelineLoggableActivityType[]
  >([])
  const [filterActivityId, setFilterActivityId] = useState<string>('')
  const [filterEmail, setFilterEmail] = useState<string>('')
  const [filterPhoneNumber, setFilterPhoneNumber] = useState<string>('')
  const [filterOrderId, setFilterOrderId] = useState<string>('')
  const [filterPersonId, setFilterPersonId] = useState<string>('')
  const [filterActivityName, setFilterActivityName] = useState<string>('')
  const [filterSourceUrl, setFilterSourceUrl] = useState<string>('')
  const [filterOccurredAfter, setFilterOccurredAfter] = useState<moment.Moment>(
    moment().subtract(5, 'days')
  )
  const [filterOccurredBefore, setFilterOccurredBefore] = useState<moment.Moment>(
    moment().add(1, 'days')
  )
  const [filterDiagnosticSessionId, setFilterDiagnosticSessionId] = useState(
    queryParams.get('diagnosisSessionId') || ''
  )
  const [currentPage, setCurrentPage] = useState<number>(0)

  const [logs, setLogs] = useState<ConvergeActivityLogRecord[]>([])
  const [totalCount, setTotalCount] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(defaultRowsPerPage)
  const [isStreaming, setIsStreaming] = useState<boolean>(true)
  const [isManualPause, setIsManualPause] = useState<boolean>(false)
  const [loadErrorMessage, setLoadErrorMessage] = useState<string>('')

  const [openLogIds, setOpenLogIds] = useState<string[]>([])

  const onOpenToggle = (isOpen: boolean, row: ConvergeActivityLogRecord) => {
    if (isOpen) {
      setOpenLogIds([...openLogIds, row.id])
    } else {
      setOpenLogIds(openLogIds.filter((id) => id !== row.id))
    }
  }

  const convergeInstanceId = queryParams.get('convergeInstanceId') as string

  const loadLogs = async () => {
    try {
      const response = await api.converge.listDiagnosisLogs(
        convergeInstanceId,
        ConvergeActivityLogListRequest.fromJS({
          convergeInstanceId,
          diagnosisSessionIdentifier: filterDiagnosticSessionId,
          activityId: filterActivityId,
          email: filterEmail,
          phone: filterPhoneNumber,
          orderIdentifier: filterOrderId,
          occurredAfter: filterOccurredAfter,
          occurredBefore: filterOccurredBefore || moment(),
          personId: filterPersonId,
          activityNames: filterActivityName ? [filterActivityName] : [],
          sourceUrl: filterSourceUrl,
          types: filterLogType,
          levels: filterLogLevel,
          limit: pageSize,
          skip: currentPage * pageSize,
        })
      )
      setTotalCount(response.result.totalCount)
      setLogs(response.result.items)
      setLoadErrorMessage('')
      setOpenLogIds([])
    } catch (err) {
      const message =
        err?.error?.validation?.properties?.[0].errorMessage ||
        err?.error?.message ||
        'An error occurred.'
      setLoadErrorMessage(message)
    }
  }

  // useEffect(() => {
  //   loadLogs()
  // }, [])

  useEffect(() => {
    if (!isTabActive) {
      setIsStreaming(false)
    } else {
      if (openLogIds.length === 0) {
        setIsStreaming(true)

        if (!isManualPause) {
          loadLogs()
        }
      }
    }
  }, [isTabActive])

  useEffect(() => {
    setIsStreaming(true)
  }, [
    filterDiagnosticSessionId,
    filterActivityId,
    filterEmail,
    filterPhoneNumber,
    filterOrderId,
    filterOccurredAfter,
    filterOccurredBefore,
    filterPersonId,
    filterActivityName,
    filterSourceUrl,
    filterLogType,
    filterLogLevel,
    currentPage,
    pageSize,
  ])

  useEffect(() => {
    if (isStreaming && !isManualPause) loadLogs()
    const timer = setInterval(() => {
      if (isStreaming && !loadErrorMessage && !isManualPause) {
        loadLogs()
      }
    }, 3000)
    return () => clearInterval(timer)
  }, [
    isStreaming,
    isManualPause,
    filterDiagnosticSessionId,
    filterActivityId,
    filterEmail,
    filterPhoneNumber,
    filterOrderId,
    filterOccurredAfter,
    filterOccurredBefore,
    filterPersonId,
    filterActivityName,
    filterSourceUrl,
    filterLogType,
    filterLogLevel,
    currentPage,
  ])

  const commonInputProps = (onClear?: () => void) => {
    return {
      size: 'small',
      fullWidth: true,
      autoComplete: 'off',
      InputProps: {
        endAdornment: onClear ? (
          <InputAdornment position="end">
            <IconButton
              aria-label="clear filter"
              size="small"
              onClick={onClear}
              edge="end"
            >
              <Clear />
            </IconButton>
          </InputAdornment>
        ) : undefined,
      },
    } as TextFieldProps
  }

  const commonInputGridItemProps = {
    sm: 12,
  } as GridProps

  const filterInputs = (
    <>
      <Grid item {...commonInputGridItemProps}>
        <TextField
          id="filterOccurredAfter"
          label="Start"
          type="datetime-local"
          value={filterOccurredAfter.format('YYYY-MM-DDTHH:mm')}
          onChange={(e) => setFilterOccurredAfter(moment(e.target.value))}
          {...commonInputProps()}
        />
      </Grid>
      <Grid item {...commonInputGridItemProps}>
        <TextField
          id="filterOccurredBefore"
          label="End"
          type="datetime-local"
          value={(filterOccurredBefore || moment()).format('YYYY-MM-DDTHH:mm')}
          onChange={(e) => setFilterOccurredBefore(moment(e.target.value))}
          {...commonInputProps()}
        />
      </Grid>
      <Grid item {...commonInputGridItemProps}>
        <TextField
          id="filterActivityName"
          label="Event name"
          value={filterActivityName}
          onChange={(e) => setFilterActivityName(e.target.value as any)}
          {...commonInputProps(() => setFilterActivityName(''))}
        />
      </Grid>
      <Grid item {...commonInputGridItemProps}>
        <TextField
          id="filterActivityId"
          label="Event ID"
          value={filterActivityId}
          onChange={(e) => setFilterActivityId(e.target.value)}
          {...commonInputProps(() => setFilterActivityId(''))}
        />
      </Grid>
      <Grid item {...commonInputGridItemProps}>
        <TextField
          id="filterEmail"
          label="Email"
          value={filterEmail}
          onChange={(e) => setFilterEmail(e.target.value)}
          {...commonInputProps(() => setFilterEmail(''))}
        />
      </Grid>
      <Grid item {...commonInputGridItemProps}>
        <TextField
          id="filterPhoneNumber"
          label="Phone"
          value={filterPhoneNumber}
          onChange={(e) => setFilterPhoneNumber(e.target.value)}
          {...commonInputProps(() => setFilterPhoneNumber(''))}
        />
      </Grid>
      <Grid item {...commonInputGridItemProps}>
        <TextField
          id="filterSourceUrl"
          label="URL"
          value={filterSourceUrl}
          onChange={(e) => setFilterSourceUrl(e.target.value)}
          {...commonInputProps(() => setFilterSourceUrl(''))}
        />
      </Grid>
      <Grid item {...commonInputGridItemProps}>
        <TextField
          id="filterPersonId"
          label="Identity ID"
          value={filterPersonId}
          onChange={(e) => setFilterPersonId(e.target.value)}
          {...commonInputProps(() => setFilterPersonId(''))}
        />
      </Grid>
      <Grid item {...commonInputGridItemProps}>
        <TextField
          id="filterOrderId"
          label="Order ID"
          value={filterOrderId}
          onChange={(e) => setFilterOrderId(e.target.value)}
          {...commonInputProps(() => setFilterOrderId(''))}
        />
      </Grid>
      <Grid item {...commonInputGridItemProps}>
        <TextField
          id="filterDiagnosticSessionId"
          label="Session ID"
          value={filterDiagnosticSessionId}
          onChange={(e) => setFilterDiagnosticSessionId(e.target.value)}
          {...commonInputProps(() => setFilterDiagnosticSessionId(''))}
        />
      </Grid>
      <Grid item {...commonInputGridItemProps}>
        <FormControl fullWidth>
          <InputLabel id="filterLogLevel-label">Log level</InputLabel>
          <Select
            labelId="filterLogLevel-label"
            id="filterLogLevel"
            multiple
            fullWidth
            value={filterLogLevel}
            onChange={(e) => setFilterLogLevel(e.target.value as any)}
            input={<Input />}
          >
            <MenuItem value={ConvergeSdkLogEventLevel.Verbose}>
              {sentenceCase(ConvergeSdkLogEventLevel.Verbose.toString())}
            </MenuItem>
            <MenuItem value={ConvergeSdkLogEventLevel.Information}>
              {sentenceCase(ConvergeSdkLogEventLevel.Information.toString())}
            </MenuItem>
            <MenuItem value={ConvergeSdkLogEventLevel.Warning}>
              {sentenceCase(ConvergeSdkLogEventLevel.Warning.toString())}
            </MenuItem>
            <MenuItem value={ConvergeSdkLogEventLevel.Error}>
              {sentenceCase(ConvergeSdkLogEventLevel.Error.toString())}
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item {...commonInputGridItemProps}>
        <FormControl fullWidth>
          <InputLabel id="filterLogType-label">Log type</InputLabel>
          <Select
            labelId="filterLogType-label"
            id="filterLogType"
            multiple
            fullWidth
            value={filterLogType}
            onChange={(e) => setFilterLogType(e.target.value as any)}
            input={<Input />}
          >
            <MenuItem value={ConvergePipelineLoggableActivityType.ActivityEgress}>
              {sentenceCase(
                ConvergePipelineLoggableActivityType.ActivityEgress.toString()
              )}
            </MenuItem>
            <MenuItem value={ConvergePipelineLoggableActivityType.ActivityIngress}>
              {sentenceCase(
                ConvergePipelineLoggableActivityType.ActivityIngress.toString()
              )}
            </MenuItem>
            <MenuItem
              value={ConvergePipelineLoggableActivityType.PipelineIntegrationOutput}
            >
              Pipeline
            </MenuItem>
            <MenuItem value={ConvergePipelineLoggableActivityType.UserProfile}>
              {sentenceCase(ConvergePipelineLoggableActivityType.UserProfile.toString())}
            </MenuItem>
            <MenuItem value={ConvergePipelineLoggableActivityType.SdkLog}>
              {sentenceCase(ConvergePipelineLoggableActivityType.SdkLog.toString())}
            </MenuItem>
            <MenuItem value={ConvergePipelineLoggableActivityType.Diagnosis}>
              {sentenceCase(ConvergePipelineLoggableActivityType.Diagnosis.toString())}
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>

      {/* <Grid item {...commonInputGridItemProps}>
          <TextField
            id="filterLogType"
            label="Log type"
            value={filterLogType}
            onChange={(e) => setFilterLogType(e.target.value)}
            {...commonInputProps(() => setFilterLogType(''))}
          />
      </Grid> */}
    </>
  )

  const pagination = (
    <TablePagination
      rowsPerPageOptions={[5, 10, 20, 50, 100]}
      component="div"
      count={totalCount}
      rowsPerPage={pageSize}
      page={currentPage}
      onPageChange={(_event: unknown, newPage: number) => {
        setCurrentPage(newPage)
      }}
      onRowsPerPageChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        setPageSize(+event.target.value)
        setTimeout(() => setCurrentPage(0), 1000)
      }}
    />
  )

  return (
    <>
      <Topbar
        handleExit={() => history.push('/settings/developer/diagnostics/')}
        title="Server diagnostic streamer"
        rightItems={
          <>
            <Box marginRight={4}>
              <PrivacyAlert
                id="pii-logs-alert"
                title="Why can't I see PII?"
                popoverProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                  },
                }}
              >
                <div style={{ maxWidth: '400px' }}>
                  <Typography variant="body2" gutterBottom>
                    To protect your customer's privacy, personally identifiable
                    information (PII) has been redacted.
                  </Typography>
                  <Typography variant="body2">
                    Searching your logs by PII that you provide is supported.
                  </Typography>
                </div>
              </PrivacyAlert>
            </Box>

            <Button
              color={!isManualPause ? 'primary' : 'secondary'}
              variant="contained"
              disableElevation
              onClick={() => setIsManualPause(!isManualPause)}
            >
              {!isManualPause ? 'Pause' : 'Resume'}
            </Button>
          </>
        }
      />
      <div
        style={{
          margin: 'auto',
          marginTop: 100,
          paddingLeft: 32,
          paddingRight: 32,
        }}
      >
        <Grid container spacing={4}>
          <Grid item xs={12} md={3} lg={2} xl={1}>
            <Grid container spacing={3}>
              {filterInputs}
            </Grid>
          </Grid>
          <Grid item xs={12} md={9} lg={10} xl={11}>
            {loadErrorMessage ? (
              <Paper elevation={0} style={{ padding: 4 }}>
                <Alert severity="error">{loadErrorMessage}</Alert>
              </Paper>
            ) : logs.length === 0 ? (
              <Paper elevation={0} style={{ padding: 4 }}>
                <Alert severity="info">Uh... nothing found!</Alert>
              </Paper>
            ) : (
              <>
                {pagination}
                <TableContainer>
                  <Table className={classes.table} size="small" aria-label="log stream">
                    <TableBody>
                      {logs.map((row) => (
                        <DataRow
                          onOpenToggle={onOpenToggle}
                          key={row.id}
                          theme={theme}
                          setFilterActivityId={setFilterActivityId}
                          setFilterActivityName={setFilterActivityName}
                          setFilterDiagnosticSessionId={setFilterDiagnosticSessionId}
                          setFilterPersonId={setFilterPersonId}
                          setFilterSourceUrl={setFilterSourceUrl}
                          row={row}
                        />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                {pageSize > 5 ? pagination : null}
              </>
            )}
          </Grid>
        </Grid>
      </div>
    </>
  )
}
